export enum ABCClassification {
  A = 1,
  B = 2,
  C = 3,
  EXCLUDED = 5,
  SA = 4,
  UNDEFINED = 0,
}
export enum AWBPrintType {
  AWB_PRINT_TYPE_HTML = 2,
  AWB_PRINT_TYPE_PDF = 1,
}
export enum AbcActivityType {
  HIGH_VALUE = 'high_value',
  TEMPERATURE_CONTROL = 'temperature_control',
}
export enum AbcCriteria {
  ITEMS = 0,
  ORDERS = 1,
  PICKS = 2,
}
export enum AbcDailySalesIntervalToDays {
  '15_DAYS' = 1,
  '30_DAYS' = 2,
  '60_DAYS' = 4,
  '7_DAYS' = 0,
  '90_DAYS' = 3,
}
export enum ActOp {
  INBOUND = 2,
  INVENTORY = 3,
  MOVE__TRANSFER = 6,
  OTHERS = 7,
  OUTBOUND = 1,
  RETURN__INBOUND = 5,
  RTS = 4,
}
export enum ActType {
  DIRECT = 1,
  INDIRECT = 2,
  UNPRODUCTIVE = 3,
}
export enum AdjustAdditionReason {
  INBOUND_EXCESS_QTY = 7,
  OUTBOUND_WRONG_ITEM = 6,
  RECOVER_WRONGLY_ADJUSTED_SKU_QTY = 8,
  UNIDENTIFIED_REASON = 9,
}
export enum AdjustAvAgeFlag {
  '<' = 2,
  '=' = 3,
  '>' = 1,
}
export enum AdjustDeductionReason {
  BUYER_REPLACEMENT = 5,
  DAMAGE_ITEM = 1,
  EXPIRING_EXPIRY = 2,
  MANUAL_ORDER_FULFILLMENT = 4,
  MISSING_ITEM = 3,
}
export enum AdjustSourceFrom {
  MANUAL_CREATION = 1,
  RT_TROUBLESHOOT_TASK = 3,
}
export enum AdjustSyncPMSStatus {
  ADJUST__SENT = 5,
  ADJUST__UNSENT = 4,
  APPLY__SENT = 2,
  APPLY__UNSENT = 1,
  PMS__REPLY = 3,
}
export enum AdjustTaskSkuLocationStatus {
  CANCEL = 9,
  DONE = 8,
  ON_GOING = 10,
  PENDING = 1,
}
export enum AdjustTaskStatus {
  Created = 1,
  Pending = 2,
  Locked = 21,
  Rejected = 5,
  Approved = 4,
  PMS_Approving = 3,
  Adjusting = 6,
  Done = 8,
  Cancel = 9,
  Fail = 10,
}
export enum AdjustType {
  ADDITION_ = 2,
  DEDUCTION = 1,
}
export enum AllActiveLocationFrequency {
  DAYS_7 = 0,
  DAYS_14 = 1,
  DAYS_30 = 2,
  DAYS_90 = 3,
}
export enum AllocateRuleRuleType {
  RTS__OUTBOUND = 1,
  SALES__OUTBOUND = 0,
}
export enum ApproveConfigurationApprove {
  N = 0,
  Y = 1,
}
export enum ApproveConfigurationTaskType {
  INVENTORY_MANAGEMENT = 0,
}
export enum ArrivingTaskOrder {
  ASC = 0,
  DESC = 1,
}
export enum ArrivingTaskOrderKey {
  CREATE_TIME = 2,
  TIME_SINCE_CREATION = 1,
  TIME_SINCE_LAST_MODIFIED = 3,
}
export enum ArrivingTaskStatus {
  PENDING = 10,
  ONGOING = 20,
  DONE = 30,
  CANCELLED = 40,
}
export enum AsnGroupStatus {
  CANCELLED = 1,
  DONE = 6,
  PENDING = 2,
  PUTTING = 5,
  RECEIVED = 4,
  SCANNING = 3,
}
export enum AsnQuotaStatus {
  OFF = 1,
  ON = 2,
}
export enum BatchTransferRelatedSheetType {
  BATCH_TRANSFER = 0,
  MOVE_TRANSFER_INBOUND = 1,
}
export enum BatchTransferStatus {
  CANCEL = 9,
  DONE = 10,
  ONGOING = 5,
  PENDING = 1,
}
export enum BatchTransferTaskStatus {
  CANCEL = 9,
  DONE = 10,
  ONGOING = 5,
  PENDING = 1,
}
export enum BatchTransferTaskType {
  NORMAL = 1,
  QUALITY_TRANSFER = 2,
}
export enum BlockReasonType {
  BATCH_TRANSFER = 'Batch transfer',
  EXPIRING_TO_EXPIRED = 'Expiring to expired',
  INVENTORY_ADJUSTMENT = 'Inventory adjustment',
  OPERATOR_UNBLOCK = 'Operator Unblock',
  RACK_TRANSFER = 'Rack transfer',
  RETURN_INBOUND = 'Return Inbound',
  RETURN_ORDER = 'Return order',
  SYSTEM = 'System',
  UNKNOWN = 'Unknown',
}
export enum BlockStatusType {
  BLOCK = 2,
  FAIL = 3,
  PENDING = 1,
  UNBLOCK = 0,
}
export enum BlockTypeDisplay {
  ALL = 3,
  EXPIRED = 2,
  EXPIRING = 1,
  NORMAL = 0,
}
export enum CBOption {
  CB = 1,
  LOCAL = 0,
}
export enum CategoryLevel {
  CATEGORY_LEVEL_L1 = 1,
  CATEGORY_LEVEL_L2 = 2,
  CATEGORY_LEVEL_L3 = 3,
  CATEGORY_LEVEL_L4 = 4,
  CATEGORY_LEVEL_L5 = 5,
}
export enum CategoryOperationType {
  CATEGORY_OPERATION_TYPE_HIGH_VALUE_FLAG_CHANGE = 5,
  CATEGORY_OPERATION_TYPE_INIT = 0,
  CATEGORY_OPERATION_TYPE_LIFE_CYCLE_RULE_CHANGE = 2,
  CATEGORY_OPERATION_TYPE_SCBS_CHANGE = 3,
  CATEGORY_OPERATION_TYPE_SHELF_LIFE_FLAG_CHANGE = 1,
  CATEGORY_OPERATION_TYPE_TEMP_ZONE_CHANGE = 4,
}
export enum CategoryTemperatureControl {
  NORMAL = 0,
  AIR_CONDITIONED = 1,
  CHILLED = 2,
  FROZEN = 3,
  DEEP_FROZEN = 4,
}
export enum CdnFolder {
  APP_DOWNLOAD_URL_PARENT_PATH = '/appdownloads',
  AWB_LABEL = '/awb_label',
  BLOCK_FILE_DOWNLOAD = '/inventory_map/block',
  CELL_DOWNLOAD = '/cell',
  CYCLE_COUNT_DOWNLOAD = '/cycle_count/excel',
  DAILY_SALES = '/daily_sales_map',
  EXPORT_ACTIVITY = '/export_activity',
  EXPORT_ACTIVITY_LIST = '/export_activity_list',
  EXPORT_CELL = '/export_cell',
  EXPORT_CONSUMABLES = '/mconsumables',
  EXPORT_CYCLE_COUNT_ORDER = '/export_cycle_count_order',
  EXPORT_DAILY_SALES = '/export_daily_sales',
  EXPORT_DEVICE = '/export_device',
  EXPORT_EXCEPTION_CENTER = '/export_exception_center',
  EXPORT_INBOUND = '/export_inbound',
  EXPORT_MERGING_TASK = '/merging_task',
  EXPORT_OUTBOUND_SALE_CHECKING = '/outbound_sale_checking',
  EXPORT_OUTBOUND_SALE_ORDER = '/outbound_sale_order',
  EXPORT_OUTBOUND_SALE_PACKING_USER = '/outbound_sale_packing_user',
  EXPORT_OUTBOUND_SALE_PICKING = '/outbound_sale_picking',
  EXPORT_OUTBOUND_SALE_SALE_WEIGHT_VALIDATE = '/outbound_sale_weight_walidate',
  EXPORT_OUTBOUND_SALE_SHIPPING = '/outbound_sale_shipping',
  EXPORT_PDA_LOGIN_LOG = '/export_pda_login_log',
  EXPORT_PICKER_SKILL = '/export_picker_skill',
  EXPORT_QC_RULE = '/export_qc_rule',
  EXPORT_RETURN_INBOUND = '/export_return_inbound',
  EXPORT_SHOP = '/export_shop',
  EXPORT_SKU = '/export_sku',
  EXPORT_STAFF = '/export_staff',
  EXPORT_STAFF_ACTIVITY = '/export_staff_activity',
  EXPORT_STAFF_ATTENDANCE = '/export_staff_attendance',
  EXPORT_STAFF_LIST = '/export_staff_list',
  EXPORT_STOCK = '/export_stock',
  EXPORT_SUB_PICKING_TASK = '/sub_picking',
  EXPORT_SUPPLIER = '/export_supplier',
  EXPORT_TRANSITING_TASK = '/transiting_task',
  GIFT_IMAGE = '/gift_image',
  GIFT_TASK_DOWNLOAD = '/gift',
  INBOUND_ASN_TRACKING_ID = '/inbound_tracking',
  INBOUND_EXCEPTION_IMAGE = '/inbound_exception_image',
  INBOUND_MAP_DOWNLOAD = '/inbound_map',
  INBOUND_SKU_MAP_DOWNLOAD = '/inbound_sku_map',
  INTRANSIT_INVENTORY_DOWNLOAD = '/intransit_inventory',
  INVENTORY_ADJUSTMENT = '/Inventory_adjustment',
  INVENTORY_ADJUST_DOWNLOAD = '/inventory_adjust',
  INVENTORY_MAP_DOWNLOAD = '/inventory_map',
  INV_MAP_AVAILABLE_DOWNLOAD = '/inventory_map/available',
  INV_MAP_EXPIRY_DOWNLOAD = '/inventory_map/expiry',
  INV_MAP_ONHAND_DOWNLOAD = '/inventory_map/onhand',
  LOCATION_OCCUPATION_DOWNLOAD = '/location_occupation_map',
  MOVE_TRANSFER_ARRIVAL_ORDER_DOWNLOAD = '/move_transfer_inbound/arrival_orders',
  MOVE_TRANSFER_INBOUND_DOWNLOAD = '/move_transfer_inbound',
  MOVE_TRANSFER_OUTBOUND_PACKING_DOWNLOAD = '/mto_outbound/packing_task',
  MOVE_TRANSFER_OUTBOUND_SHIP_DOWNLOAD = '/mto_outbound/ship_order',
  ORDERS_DOWNLOAD = '/orders',
  OUTBOUND_SHIPPED_TASK_DOWNLOAD = '/outbound_shipped_orders',
  PACKERS_PRODUCTIVITY_DOWNLOAD = '/packers_productivity',
  PACKING_TASK_DOWNLOAD = '/packing_task',
  PDA_ARRIVAL = '/pda_arrival',
  PDA_PACKAGE = '/pda_package',
  PICKING_TASK_DOWNLOAD = '/picking_task',
  RACK_TRANSFER_DOWNLOAD = '/rack_transfer/excel',
  REPLENISHMENT = '/replenishment_map',
  REPORT_ACCESS = '/report_access',
  RETURN_INBOUND_QC = '/return_inbound_qc_images',
  RETURN_ORDER_DOWNLOAD = '/rts_order',
  REVERSE_TASK_DOWNLOAD = '/reverse_task',
  RTS_AVAILABLE_QUANTITY = '/rts_available_quantity',
  RTS_RECONCILIATION = '/rts_reconciliation',
  SHIP_ORDER_DOWNLOAD = '/ship_orders',
  SKU_ITEM_DOWNLOAD = '/sku_item',
  SKU_LOG_DOWNLOAD = '/sku_log/excel',
  TRAN_MAP_DOWNLOAD = '/transaction_map',
  TREE = '/Folder_tree',
  UN_BLOCK_FILE_DOWNLOAD = '/inventory_map/unblock',
  UPLOADED_FILES_PATH = '/uploaded_files',
  USER_DOWNLOAD = '/user/excel',
  WEIGHT_VALIDATION_DOWNLOAD = '/weight_validation',
  WRONG_SKU_IMAGE = '/wrong_sku_image',
}
export enum CellStatus {
  BLOCKED = 2,
  DELETED = 4,
  FROZEN = 3,
  NORMAL = 1,
  UNDEFINED = 0,
}
export enum CellStructLevel {
  CELL = 3,
  PATHWAY = 2,
  UNDEFINED = 0,
  ZONE = 1,
}
export enum CheckinTaskCheckMethod {
  Checking = 0,
  Mass_Checking = 2,
  Sort_While_Checking = 1,
}
export enum CheckinTaskPrintTag {
  Y = 1,
  N = 0,
}
export enum CheckingTaskMassOrderFlag {
  Y = 1,
  N = 0,
}
export enum CheckingTaskSbsFlag {
  Y = 0,
  N = 1,
}
export enum CheckingTaskSourceType {
  PICKING__ID = 0,
}
export enum CheckingTaskStatus {
  Pending = 0,
  Ongoing = 1,
  Done = 2,
  Cancel = 9,
}
export enum CollectUnitType {
  PUTAWAY = 1,
  RECEIVING = 2,
  UNDEFINED = 0,
}
export enum CommonEnum {
  NO = 0,
  YES = 1,
}
export enum CommonEnumYAndN {
  Y = 1,
  N = 0,
}
export enum ConfAvailableType {
  Disable = 0,
  Enabled = 1,
}
export enum ConfLevelType {
  Region = 1,
  Warehouse = 2,
}
export enum ConfNameOrder {
  ASC = 0,
  DESC = 1,
}
export enum ConfOrderKey {
  ConfName = 2,
  Mtime = 1,
}
export enum ConsumableManualRemovalTaskStatus {
  DONE = 2,
  ONGOING = 1,
}
export enum ConsumableStockTakeCreateType {
  BY__ID = 2,
  BY__TYPE = 1,
}
export enum ConsumableStockTakeTaskDetailStatus {
  DONE = 2,
  ONGOING = 1,
  PENDING = 0,
}
export enum ConsumableStockTaskStatus {
  CANCEL = 7,
  DONE = 2,
  ONGOING = 1,
  PENDING = 0,
}
export enum ConsumableType {
  FILLERS = 4,
  OTHERS = 6,
  PACKING_BOX = 1,
  POUCH = 2,
  STATIONERIES = 3,
  STRETCH_FILMS = 5,
}
export enum ConsumableWithMultiType {
  ALL = 8,
  FILLERS = 4,
  MIX = 7,
  OTHERS = 6,
  PACKING_BOX = 1,
  POUCH = 2,
  STATIONERIES = 3,
  STRETCH_FILMS = 5,
}
export enum ConsumablesStatus {
  OFF = 0,
  ON = 1,
  UNDEFINE = 2,
}
export enum ContractType {
  CONTRACT = 3,
  PERMANENT = 1,
  TEMPORARY = 2,
}
export enum CountryRegionType {
  CN = 'CN',
  ID = 'ID',
  MY = 'MY',
  PH = 'PH',
  SG = 'SG',
  SZ = 'SZ',
  TH = 'TH',
  TW = 'TW',
  VN = 'VN',
}
export enum CrateReplWaveOrderFilter {
  CUT_OFF_TIME = 1,
  ONLY_URGENT_ORDER = 3,
  URGENT_CREATE_TIME = 2,
}
export enum CreateReplWaveCreateBySku {
  N = 0,
  Y = 1,
}
export enum CreateReplWavePaperless {
  N = 0,
  Y = 1,
}
export enum CreateReplWaveReplType {
  ALL = 5,
  FORWARD_ONLY = 6,
  REVERSE_ONLY = 7,
}
export enum CreateRplWaveCrossZoneCluster {
  N = 0,
  Y = 1,
}
export enum ReplRunWaveViewRackTransferType {
  AUTO = 0,
  ONE_STEP = 1,
  TWO_STEP = 2,
  THREE_STEP = 3,
}
export enum CreateRplWaveSkuSalesType {
  ALL = 4,
  CAMPAIGN_SALES = 2,
  FLASH_SALES = 1,
  ORGANIC_SALES = 3,
}
export enum CycleCountDashboardCurrentQuarter {
  APR_JUN = 1,
  JAN_MAR = 0,
  JUL_SEP = 2,
  OCT_DEC = 3,
}
export enum CycleCountDiffOrderItemNeedHandle {
  '-' = 2,
  N = 0,
  Y = 1,
}
export enum CycleCountDiffOrderItemStatus {
  APPROVED = 2,
  PENDING = 1,
  REJECTED = 3,
}
export enum CycleCountDiffOrderStatus {
  DONE = 3,
  ONGOING = 2,
  PENDING = 1,
}
export enum CycleCountIsCountedInCurrentQuarter {
  ALL = 2,
  N = 0,
  Y = 1,
}
export enum CycleCountIsLocationMovement {
  N = 0,
  Y = 1,
}
export enum CycleCountDailyLocationCCOperateFunction {
  NORMAL = 2,
}
export enum CycleCountLocationCCOperateFunction {
  EMPTY_LOCATION_COUNT = 3,
  LESS_QTY_COUNT = 4,
  NORMAL = 2,
}
export enum CycleCountMethod {
  EXPRESS_CYCLE_COUNT = 1,
  FULL_CYCLE_COUNT = 2,
}
export enum CycleCountMovementCCOperateFunction {
  PICKING_COUNT = 6,
  PUTAWAY_COUNT = 5,
  RACK_TRANSFER_COUNT = 7,
}
export enum CycleCountOrderIsDiff {
  '-' = 2,
  N = 0,
  Y = 1,
}
export enum CycleCountOrderStatus {
  PENDING = 1,
  ONGOING = 2,
  DONE = 4,
  CANCEL = 3,
}
export enum CycleCountSkuCCOperateFunction {
  NORMAL = 1,
}
export enum CycleCountSourceFrom {
  DAILY_CYCLE_COUNT = 3,
  MANUAL_CREATION = 1,
  OB_TROUBLE_SHOOT = 2,
  FULL_STOCK_COUNT = 4,
}
export enum CycleCountStatus {
  CHECKING = 2,
  NORMAL = 1,
  STATIC_LOCK = 3,
  UNDEFINED = 0,
}
export enum CycleCountTaskAssignEvent {
  ASSIGN = 1,
  RELEASE = 0,
}
export enum CycleCountTaskAssignStatus {
  ALL = 4,
  ASSIGNED_TO_ME = 1,
  ASSIGNED_TO_OTHERS = 2,
  UNASSIGNED = 3,
}
export enum CycleCountTaskCreateFrom {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}
export enum CycleCountTaskIsDiff {
  UNDEFINE = 2,
  N = 0,
  Y = 1,
}
export enum CycleCountTaskIsOnHold {
  ALL = 2,
  N = 0,
  Y = 1,
}
export enum CycleCountTaskIsUrgent {
  ALL = 2,
  N = 0,
  Y = 1,
}
export enum CycleCountTaskOrder {
  ASC = 0,
  DESC = 1,
}
export enum CycleCountTaskOrderKey {
  Ctime = 2,
  Urgent = 1,
}
export enum CycleCountTaskSkuItemIsDiff {
  UNDEFINE = 2,
  N = 0,
  Y = 1,
}
export enum CycleCountTaskSkuItemStatus {
  CANCEL = 4,
  DONE = 3,
  ONGOING = 2,
  PENDING = 1,
}
export enum CycleCountTaskStage {
  '01ST_COUNT' = 1,
  '02ND_COUNT' = 2,
  '03RD_COUNT' = 3,
  '04TH_COUNT' = 4,
  '05TH_COUNT' = 5,
  '06TH_COUNT' = 6,
  '07TH_COUNT' = 7,
  '08TH_COUNT' = 8,
  '09TH_COUNT' = 9,
  '10TH_COUNT' = 10,
}
export enum CycleCountTaskStatus {
  ASSIGNED = 5,
  CANCEL = 3,
  DONE = 4,
  ONGOING = 2,
  PENDING = 1,
}
export enum CycleCountRecommendationLocationType {
  ALL_ACTIVE_LOCATIONS = 0,
  CUSTOMIZED_LOCATIONS = 2,
  ONLY_HIGH_VALUE = 1,
}
export enum CycleCountRecommendationSkuType {
  CUSTOMIZED_SKU = 0,
}
export enum CycleCountType {
  LOCATION_CC = 2,
  MOVEMENT_CC = 3,
  SKU_CC = 1,
}
export enum CycleCountUnitCountStatus {
  MISSING = 2,
  NORMAL = 0,
  SURPLUS = 1,
}
export enum DFBizType {
  OUTBOUND = 3,
  INVENTORY = 2,
  INBOUND = 1,
}
export enum DFRegion {
  SG = 'SG',
  TW = 'TW',
}
export enum DFStatus {
  PENDING = 0,
  RUNNING = 1,
  KILLED = 2,
  SUCCESS = 3,
  FAIL = -1,
}
export enum DailySalesIntervalType {
  SEVEN = 0,
  FIFTEEN = 1,
  THIRTY = 2,
  SIXTY = 4,
  NINETY = 3,
}
export enum DashboardDataType {
  ITEM = 1,
  ORDER = 0,
  TRACKING_ID = 2,
}
export enum DashboardProductivityStatus {
  CHECKING = 41,
  PACKING = 42,
  PICKING = 40,
  PUTTING = 1,
}
export enum DashboardProductivityTaskStatus {
  PICKING = 40,
  CHECKING = 41,
  PACKING = 42,
}
export enum DashboardStaffAllocationType {
  ACTIVE = 2,
  BREAK = 3,
  INBOUND = 4,
  INVENTORY = 6,
  OTHERS = 8,
  OUTBOUND = 5,
  RETURN_INBOUND = 7,
  TOTAL = 1,
}
export enum DashboardTimeType {
  LAST_12_HOURS = 1,
  LAST_7_DAYS = 2,
}
export enum DashboardTrackingTimeType {
  LAST_24_HOURS = 2,
  LAST_7_DAYS = 3,
  TODAY = 1,
}
export enum DateFormatType {
  DD_MM_YYYY = 'DD/MM/YYYY',
  MM_DD_YYYY = 'MM/DD/YYYY',
  YYYY_MM_DD = 'YYYY/MM/DD',
}
export enum DeviceLogLocationStatusType {
  EMPTY = 2,
  FROM = 0,
  FROM_TO = 3,
  TO = 1,
}
export enum DeviceLogLocationType {
  FROM = 0,
  TO = 1,
}
export enum DeviceLogProgressType {
  INBOUND = 0,
  INVENTORY = 2,
  MOVE_TRANSFER = 3,
  OUTBOUND = 1,
  RETURN_INBOUND = 4,
}
export enum DeviceLogTaskType {
  CHECKING = 3,
  MERGING = 12,
  PACKING = 5,
  PICKING = 2,
  PUTAWAY = 1,
  QC = 6,
  RE_RECEIVING = 10,
  RECEIVING = 0,
  SHIPPING = 4,
  SORTING = 14,
  TRANSITING = 13,
  TWO_STEP_TRANSFER_PICKING = 8,
  TWO_STEP_TRANSFER_PUTAWAY = 9,
  WHOLE_LOCATION_TRANSFER = 7,
}
export enum DeviceProcess {
  ALL = 0,
  INBOUND = 1,
  INVENTORY = 2,
  MOVE_TRANSFER = 10,
  PICKING = 3,
  RETURN_INBOUND = 6,
  RTS = 7,
  SHIPPING = 5,
  SORTING = 4,
  TROUBLESHOOTING = 8,
}
export enum DeviceProgressInHouseGeneral {
  ALL = 0,
  INBOUND = 1,
  INVENTORY = 2,
  PICKING = 3,
  SHIPPING = 4,
  RETURN_INBOUND = 5,
  RTS = 6,
  SORTING = 7,
  TROUBLE_SHOOTING = 8,
  MERGING = 9,
}
export enum DeviceSheetType {
  INBOUND = 1,
  MOVE_TRANSFER_INBOUND = 10,
  MOVE_TRANSFER_OUTBOUND = 11,
  MTO_CHECKING_TASK = 15,
  MTO_PICKING_TASK = 12,
  MTO_SHIP_ORDER = 13,
  OUTBOUND_CHECKING = 7,
  OUTBOUND_PICKING = 4,
  OUTBOUND_SHIPPING = 5,
  OUTBOUND_SORTING = 8,
  RACK_TRANSFER = 3,
  RETURN_INBOUND = 2,
  RETURN_TO_SUPPLIER = 6,
  UNDEFINED = 0,
}
export enum DeviceStatus {
  DAMAGED = 9,
  NORMAL = 0,
  USED = 2,
  USING = 1,
}
export enum DeviceType {
  BASKET = 4,
  BOX = 0,
  PALLET = 1,
  PICK_CART = 2,
  PUT_WALL = 3,
}
export enum DeviceUsageType {
  INHOUSE_GENERAL = 0,
  MOVE_TRANSFER = 1,
}
export enum DistributedIDType {
  ARRIVE_GROUP_ID = 3005,
  BATCH_NO = 102,
  BATCH_TRANSFER_ID = 2001,
  CELL_SIZE_TYPE_ID = 4002,
  CHECKING_TASK_NUMBER = 4015,
  CUSTOM_WAVE_RULE_ID = 4132,
  CYCLE_COUNT_DIFF_ORDER_ID = 2005,
  CYCLE_COUNT_ORDER_ID = 2002,
  CYCLE_COUNT_TASK_ID = 2004,
  EXPORT_TASK_ID = 1022,
  INBOUND_ID = 3001,
  INBOUND_QUEUE_ID = 3006,
  INBOUND_UAT_ID = 3007,
  INVENTORY_ADJUST_NO = 103,
  MOVE_ORDER_ID = 2003,
  MOVE_TRANSFER_ARRIVAL_ORDER_ID = 3003,
  MOVE_TRANSFER_INBOUND_ID = 3002,
  MOVE_TRANSFER_OUTBOUND_ID = 4001,
  MOVE_TRANSFER_PACKING_TASK_ID = 4003,
  MOVE_TRANSFER_SHIP_TASK_ID = 4004,
  PACKING_TASK_ID = 4006,
  PAPERLESS_PICKING_RULE_ID = 4120,
  PO_UAT_ID = 3008,
  PUTAWAY_ID = 2010,
  QC_RULE_ID = 4140,
  QC_TASK_ID = 3009,
  RACK_TRANSFER_TASK_ID = 2000,
  RACK_TRANSFER_TWO_STEP_SHEET_STATUS_CHANGE = 2020,
  REPLENISHMENT_TASK_ID = 4100,
  REPLENISHMENT_WAVE_ID = 2030,
  RETURN_BUYER_ORDER_ID = 4110,
  RETURN_INBOUND_ID = 3012,
  RETURN_ORDER_NUMBER = 1003,
  RETURN_PICKING_TASK_NUMBER = 1005,
  RETURN_SHIPPED_ORDER_NUMBER = 1004,
  REVERSE_TASK_NUMBER = 4020,
  SALES_ORDER_NUMBER = 4010,
  SALES_ORDER_PARCEL_ID = 4022,
  SHEET_STATUS_SYNC_REQUEST_ID = 103,
  SHIPPED_ORDER_TASK_ID = 1002,
  SHIPPING_TASK_TASK_NUMBER = 4016,
  SORTING_TASK_TASK_NUMBER = 4007,
  STOCK_SYNC_LOG_TAB = 1001,
  TRACKING_ID = 3004,
  TRANSACTION_ID = 101,
  TRANSACTION_UNIT_TAB = 1000,
  TROUBLESHOOTING_TASK_NUMBER = 4030,
  USER_BEHAVIOR_ID = 4200,
  WAVE_RULE_GROUP_ID = 4131,
  WAVE_RULE_ID = 4130,
  WAVE_RULE_SN = 4017,
}
export enum DockStatus {
  ASSIGNED = 2,
  NORMAL = 1,
  OCCUPIED = 3,
}
export enum EmailReminderIntervalTime {
  EVERY_1_HOUR = 60,
  EVERY_10_MINS = 10,
  EVERY_12_HOUR = 720,
  EVERY_2_HOUR = 120,
  EVERY_24_HOUR = 1440,
  EVERY_30_MINS = 30,
  NEVER = -1,
}
export enum ExceptionFrom {
  RACK_TRANSFER_PUTAWAY = 1,
}
export enum ExceptionStatus {
  CANCEL = 4,
  DONE = 3,
  ONGOING = 2,
  PENDING = 1,
}
export enum ExceptionTaskType {
  INVENTORY = 2,
}
export enum ExceptionType {
  QTY_ABNORMAL = 1,
}
export enum ExportCycleCountOrderIsDiff {
  N = 0,
  Y = 1,
}
export enum ExportModule {
  CONSUMABLE__MANAGEMENT = 14,
  DASHBOARD = 5,
  EXCEPTION__CENTER = 12,
  INBOUND = 1,
  INBOUND__OPERATOR__PRODUCTIVITY = 13,
  INVENTORY__MANAGEMENT = 4,
  INVENTORY__MAP = 3,
  LABOR__MANAGEMENT = 10,
  MOVE = 6,
  OUTBOUND = 2,
  RETURN__INBOUND = 11,
  RETURN__TO__SUPPLIER = 9,
  WMS__BASIC = 8,
  WMS__CONFIG = 7,
}
export enum ExportTaskStatus {
  PENDING = 0,
  ONGOING = 1,
  DONE = 2,
  FAIL = 3,
}
export enum ExportTaskStatusType {
  CANCEL = 9,
  DONE = 2,
  FAILED = 3,
  ONGOING = 1,
  PENDING = 0,
}
export enum ExportTaskType {
  ACTIVITY__LIST = 1004,
  ASN__LIST = 102,
  ASN__REPORT = 101,
  ASN__SKU__REPORT = 103,
  ASN__TRACKING__ID__REPORT = 104,
  AVAILABLE__INVENTORY__MAP = 92,
  BLOCK__INVENTORY__MAP = 94,
  CELL__LIST = 29,
  CHECKING__TASK = 704,
  CHECKING__WEIGHT__VALIDATION = 706,
  CONSUMABLE__MANAGEMENT__INFO = 901,
  CONSUMABLE__MANAGEMENT__LOG = 902,
  CYCLE__COUNT__ORDER = 405,
  DAILY__SALES = 402,
  DEVICE__MANAGEMENT = 89,
  EXPIRY_AGING_INVENTORY_MAP = 93,
  FULL__STOCK__COUNT = 408,
  GIFT__MANAGEMENT__REPORT = 30,
  HISTORICAL__INVENTORY__MAP = 64,
  INBOUND__LABOUR__PLANNING__REPORT = 107,
  INBOUND__OPERATOR__PRODUCTIVITY = 108,
  INVENTORY__ADJUSTMENT = 404,
  LOCATION__OCCUPATION__PERFORMANCE = 91,
  MAPPING__SKU__AVAILABLE__INVENTORY__QTY__MAP = 96,
  MAPPING__SKU__BLOCK__INVENTORY__QTY__MAP = 99,
  MAPPING__SKU__EXPIRY__INVENTORY__QTY__MAP = 98,
  MAPPING__SKU__ON_HAND__INVENTORY__QTY__MAP = 97,
  MAPPING__SKU__REPORT = 83,
  MERGING__TASK = 1051,
  MERGING__WHILE__SORTING__TASK = 1053,
  MTO__PACKING__TASK = 1101,
  MTO__SHIP__ORDER = 1102,
  MT__INBOUND__REPORT = 1201,
  OB__TOUBLESHOOT__REPORT = 510,
  ON_HAND__INVENTORY__MAP = 86,
  OUTBOUND__SKU__REPORT = 701,
  PACKER__PRODUCTIVITY__LOG = 708,
  PDA__LOGIN__LOG__EXPORT = 27,
  PICKING__TASK = 702,
  PUTAWAY__LOCATION__REPORT = 106,
  RACK__TRANSFER__ORDER = 406,
  REPLENISHMENT__TASK = 26,
  REPORT__ACCESS = 63,
  REVERSE__TASK = 709,
  RI__ORDER__REPORT = 201,
  RTB__ORDER__REPORT = 202,
  RI__RTS__ORDER__REPORT = 203,
  RI__3PL__CLAIM__REPORT = 204,
  RTS__ORDER__REPORT = 801,
  RT__TROUBLESHOOT = 403,
  SAFETY__AND__TARGET__INVENTORY = 401,
  SHIPPED__ORDER = 707,
  SHOP__LIST = 88,
  SKU__ITEM = 82,
  SKU__MODIFICATION__LOG = 90,
  STAFF__ACTIVITY = 1003,
  STAFF__ATTENDANCE = 1002,
  STAFF__LIST = 1001,
  STATION__ID__LIST = 300,
  SUB_PICKING__TASK = 1050,
  SUGGESTED__PUTAWAY__LOCATION__REPORT = 105,
  SUPPLIER__LIST = 87,
  SUPPLIER__QC__SETTINGS = 81,
  SURPLUS__REGISTRATION = 407,
  TEST__BIG__DATA__EXPORT = 9999,
  TOUBLESHOOT__REPORT = 501,
  TRANSACTION__INVENTORY__MAP = 85,
  TRANSITING__TASK = 1052,
  UNBLOCK__LOG = 95,
  USER__LIST__REPORT = 330,
  INSPECT_TASK = 425,
  FULFILL_CHAIN_REQUIREMENT = 413,
  LOGIC_AREA_REQUIREMENT = 414,
  WHS_REQUIREMENT = 415,
}
export enum GiftCategoryType {
  Commodity = 1,
  Makeup = 2,
  Food = 3,
  Stationery = 4,
  Others = 5,
}
export enum GiftCategoryTypeList {
  Commodity = 1,
  Makeup = 2,
  Stationery = 4,
  Food = 3,
  Others = 5,
}
export enum GiftOperateStationType {
  SORTING_WHILE_CHECKING = 2,
  CHECKING = 1,
  ALL = 4,
}
export enum GiftStatus {
  AVAILABLE = 0,
  UNAVAILABLE = 1,
}
export enum GiftType {
  SPECIAL_ORDER = 1,
  SPECIAL_SHOP = 2,
  SPECIAL_SKU_BY_SHOP = 3,
}
export enum GiftValidityType {
  Less_than_a_month = 1,
  Less_than_half_a_year = 2,
  Over_half_a_year = 3,
  Over_a_year = 4,
}
export enum GroupActivityTaskType {
  Counting = 1,
  Qc = 2,
  Receiving = 3,
  Putaway = 4,
  Labelling = 5,
  Bundling = 6,
  Repackaging = 7,
}
export enum GroupActivityType {
  FIX = 2,
  TEMP = 1,
}
export enum HighValueCategoryTreeDisabled {
  DISABLED = 1,
  NOT_DISABLED = 0,
}
export enum HighValueLocationFrequency {
  DAYS_7 = 0,
  DAYS_14 = 1,
  DAYS_30 = 2,
  EMPTY = 3,
}
export enum HireStatus {
  ACTIVE = 1,
  LEAVE__OF__ABSENCE = 3,
  TERMINATED = 2,
}
export enum HistoryMaintainType {
  N = 0,
  Y = 1,
}
export enum ISCPurchase {
  CONSIGNMENT = 'Consignment',
  FULFILLMENT = 'Fulfillment',
  OUTRIGHT = 'Outright',
}
export enum InboundAdditionalServiceType {
  LABELLING = 1,
  BUNDLING = 2,
  REPACKAGING = 3,
}
export enum InboundAdditionalStatus {
  PENDING = 10,
  ONGOING = 20,
  DONE = 30,
  CANCELLED = 40,
}
export enum InboundArrivalGroupOrNotType {
  DEFAULT = 0,
  GROUP = 2,
  NOT_GROUP = 1,
}
export enum InboundArrivalRejectConfig {
  CAN_NOT_REJECT = 0,
  CAN_REJECT_ALL = 2,
  REJECT_NOT_ON_TIME = 1,
}
export enum InboundArrivalTag {
  EARLY = 2,
  LATE = 3,
  ON_TIME = 1,
  UN_ARRIVED = 0,
}
export enum InboundAsnBizType {
  PURCHASE_INBOUND = 1,
  RETURN_INBOUND = 2,
  TRANSFER_INBOUND = 3,
}
export enum InboundAsnDateReferenceType {
  ACTUAL_ARRIVAL_TIME = 3,
  ASN_CREATION_TIME = 2,
  EXPECTED_DELIVERY_TIME = 1,
  TRACKING_ID_CREATE_TIME = 4,
}
export enum InboundAsnSourceType {
  CONSIGNMENT = 1,
  FBS = 2,
  OUTRIGHT = 0,
  RETURN_INBOUND = 3,
  TRANSFER_INBOUND = 4,
}
export enum InboundDeviceDisplayStatus {
  DONE = 0,
  PUTTING = 2,
  RECEIVING = 1,
}
export enum InboundExceptionTaskBdDecision {
  Raise_new_ASN = 'Raise new ASN',
  Receive = 'Receive',
  Return_to_Supplier = 'Return to Supplier',
  SKU_Info_Updated = 'SKU Info Updated',
  UPC_Updated = 'UPC Updated',
}
export enum InboundExceptionTaskPmsChangeType {
  BD_Decision = 1,
  BD_Remark = 2,
}
export enum InboundExceptionTaskReason {
  Damaged = 'Damaged',
  Expired = 'Expired',
  Expiring = 'Expiring',
  Other_issues = 'Other issues',
  SKU_does_not_belong_to_ASN_Group = 'SKU does not belong to ASN Group',
  Surplus = 'Surplus',
  Unknown_item = 'Unknown item',
  Wrong_SKU_Information = 'Wrong SKU Information',
  Wrong_UPC = 'Wrong UPC',
}
export enum InboundExceptionTaskSceneType {
  COUNTING = 1,
  QC = 2,
}
export enum InboundExceptionTaskSearchOrder {
  ASC = 1,
  DESC = 2,
}
export enum InboundExceptionTaskSearchOrderKey {
  CTIME = 1,
  PENDING_BD_TIME = 2,
}
export enum InboundExceptionTaskStatus {
  PENDING = 10,
  CHECKED = 20,
  BD_CONFIRMED = 40,
  DONE = 50,
  CANCELLED = 60,
}
export enum InboundExceptionTaskTreatment {
  Inbound_Current_Asn = 2,
  Inbound_New_Asn = 3,
  Return = 1,
  Dispose = 9,
  Inbound = 8,
  Incorrect_Reporting = 7,
  Pass_Good_Reject_Damage = 16,
  Re_Supply_Current_Asn = 5,
  Re_Supply_New_Asn = 4,
  Rejected_All = 15,
  Relabel_Inbound_Current_Asn = 10,
  Relabel_Inbound_New_Asn = 12,
  Repackage_Inbound_Current_Asn = 11,
  Repackage_Inbound_New_Asn = 13,
  Transfer_To_Av = 6,
}
export enum InboundExceptionType {
  AbnormalItem = 'Abnormal Item',
  RejectedItem = 'Rejected Item',
  Shortage = 'Shortage',
  Surplus = 'Surplus',
  WrongSKU = 'Wrong SKU',
}
export enum InboundExportRIOrderTimeType {
  ACTUAL_ARRIVAL_TIME = 2,
  CREATE_TIME = 1,
}
export enum InboundExportTroubleShootTimeType {
  CREATE_TIME = 1,
  RESOLVED_TIME = 2,
}
export enum InboundPrintStatus {
  PRINTED = 2,
  PRINT_DISABLED = 0,
  UN_PRINTED = 1,
}
export enum InboundReason {
  B2B_Virtual = 'B2B-Virtual',
  Deal = 'Deal',
  New_Product = 'New Product',
  Regular_Replenishment = 'Regular Replenishment',
}
export enum InboundRejectReason {
  CANCEL_CLOSE_PO = 3,
  OTHER = 0,
  SUPPLIER_REQUESTED = 1,
  WRONG_ASN_CREATION = 2,
}
export enum InboundReprocessTaskStatus {
  PENDING = 10,
  ONGOING = 20,
  DONE = 30,
}
export enum InboundSource {
  OMS = 3,
  PMS = 1,
  SELLER_CENTER = 5,
  SRM = 4,
  WMS = 2,
}
export enum InboundStatus {
  PENDING = 0,
  IN_TRANSIT = 13,
  ARRIVING = 12,
  ARRIVED = 6,
  COUNTING = 7,
  COUNTED = 8,
  CHECKING = 9,
  CHECKED = 10,
  SCANNING = 1,
  RECEIVED = 2,
  PUTTING = 3,
  DONE = 4,
  CANCELLED = 5,
  REJECTED = 11,
  FAILED = 15,
}
export enum InboundType {
  BUYER_RETURN = 2,
  FAILED_DELIVERY = 3,
  MOVE_INBOUND = 4,
  PURCHASE_INBOUND = 0,
  REPLACE_INBOUND = 1,
}
export enum IncludeBatchType {
  N = 'N',
  Y = 'Y',
}
export enum IncludeSkuList {
  Yes = 1,
  No = 0,
}
export enum InventoryExportAngleType {
  SKU = 1,
  UID = 2,
}
export enum InventoryFoundAt {
  ON_PATHWAY = 2,
  ON_RACK = 1,
  OTHERS = 3,
}
export enum IsFirstTimeIn {
  NO = 0,
  YES = 1,
}
export enum IsMedicalType {
  NO = 0,
  YES = 1,
}
export enum LaborActivityOperation {
  INBOUND = 2,
  INVENTORY = 3,
  MOVE__TRANSFER = 6,
  OTHERS = 7,
  OUTBOUND = 1,
  RETURN__INBOUND = 5,
  RTS = 4,
}
export enum LaborActivityStatus {
  DISABLE = 2,
  ENABLED = 1,
}
export enum LaborActivityType {
  DIRECT = 1,
  INDIRECT = 2,
  UNPRODUCTIVE = 3,
}
export enum LabourPlanningProcessNode {
  Counting = 1,
  Labeling = 5,
  Putaway = 3,
  QC = 2,
  Receiving = 4,
  Sorting_Unloading = 6,
}
export enum LabourPlanningSkuTag {
  Bulky = 4,
  CB = 8,
  Heavy = 5,
  High_Value = 6,
  New_SKU = 3,
  No_Tag = 0,
  Require_Labelling = 1,
  Require_Shelflife = 2,
  Temperature_Control = 7,
}
export enum LabourPlanningUnit {
  Man = 1,
  Manhr = 2,
}
export enum LifeCycleRuleItemType {
  BLOCK = 4,
  LOCK__UP = 3,
  NORMAL__REJECT = 1,
  PROMO__REJECT = 2,
  REBLOCK = 5,
}
export enum LocationPutAwayDeviceType {
  ALL = 0,
  BOX = 1,
  PALLET = 2,
}
export enum LocationDuringBlackOutPeriod {
  ACCUMULATED = 0,
  EXEMPTED = 1,
}
export enum LocationSKUType {
  LOCATION_SKU_TYPE_AVAILABLE = 3,
  LOCATION_SKU_TYPE_LOCKED = 1,
  LOCATION_SKU_TYPE_ONHAND = 0,
  LOCATION_SKU_TYPE_PRE_PICK = 2,
}
export enum MassUnblockTaskStatusEnum {
  Created = 0,
  Running = 1,
  PartialSuccess = 2,
  Success = 3,
}
export enum MTOPackingTaskStatus {
  Cancel = 40,
  Done = 30,
  Ongoing = 20,
  Pending = 10,
}
export enum MTOPackingTaskStatusList {
  Pending = 10,
  Ongoing = 20,
  Done = 30,
  Cancel = 40,
}
export enum MTOShippingTaskStatus {
  Cancel = 30,
  Done = 20,
  Ongoing = 10,
}
export enum MTOShippingTaskStatusList {
  Ongoing = 10,
  Done = 20,
  Cancel = 30,
}
export enum MerchantStatus {
  DELETED = 2,
  NORMAL = 1,
}
export enum MergeAt {
  Merge_Lane = 1,
  Merge_While_Sorting = 2,
}
export enum MergeSortingNextProcess {
  ABNORMAL_SUMMARY = 2,
  FINISHED = 3,
  SCAN_SKU = 1,
  UNDEFINED = 0,
}
export enum MergeTaskStatus {
  Pending = 0,
  Ongoing = 1,
  Done = 2,
  Cancel = 9,
}
export enum MergingTaskStatus {
  Pending = 0,
  Ongoing = 1,
  Done = 2,
  Cancel = 9,
}
export enum MixOfSku {
  CANNOT_MIX = 1,
  MIX_ATTRS_OF_ONE_SKU = 2,
  MIX_SKU = 3,
  UNDEFINED = 0,
}
export enum MixOfSupplier {
  CANNOT_MIX = 2,
  MIX_SUPPLIER = 1,
  UNDEFINED = 0,
}
export enum MoveTransferOutboundOrderCancelReason {
  Wrongly_Created = 1,
  Missing_Physical_Stock = 2,
  Plan_Changes = 3,
  Other = 4,
}
export enum MoveTransferOutboundOrderCreateChannel {
  WMS = 0,
  PMS = 1,
}
export enum MoveTransferOutboundStatus {
  Pending = 10,
  Allocated = 40,
  Approved = 20,
  Rejected = 30,
  Outbounding = 130,
  Shipping = 100,
  In_Transit = 140,
  Cancel = 120,
  Inbounding = 150,
  Done = 160,
}
export enum MtOrderSource {
  WMS = 0,
  PMS = 1,
}
export enum MtbQualityType {
  GOOD = 1,
  DAMAGE = 2,
  MISSING = 3,
}
export enum MtbStatus {
  CLOSED = 0,
  USING = 1,
  USED = 2,
}
export enum MtoCheckingTaskStatus {
  Pending = 0,
  Ongoing = 1,
  Done = 2,
}
export enum MtoPickTaskStatus {
  Assigned = 10,
  Cancel = 9,
  Done = 2,
  Ongoing = 1,
  Pending = 0,
}
export enum MultiWhsType {
  N = 0,
  Y = 1,
}
export enum NormalRuleType {
  EMPTY_LOCATION_RECOMMEND = 1,
  SAME_SKU_KEY_BATCH_ATTR_NOT_MIX = 4,
  SAME_SKU_SAME_BATCH_INFO_RECOMMEND = 2,
  SAME_SKU_SAME_KEY_BATCH_ATTR_RECOMMEND = 3,
  SIMILARITY_SKU_NOT_MIX = 5,
}
export enum NotificationSoundType {
  MUTE = 2,
  SOUND = 1,
}
export enum NotificationActivity {
  ADJUSTMENT_PUTAWAY = 16,
  ARRIVING = 1,
  ASN_CREATION = 17,
  BATCH_PICKING = 19,
  CHECKING = 12,
  COUNTING = 2,
  MASS_PUTWAY = 6,
  ONE_STEP_TRANSFER = 26,
  PACKING = 13,
  PAPERLESS_PICKING = 21,
  PICKING = 9,
  QC = 3,
  QC_AND_RECIEVING = 7,
  RACK_TRANSFER_PICKING = 15,
  RECIEVING = 4,
  RETURN_TO_BUYER = 18,
  RTS_PICKING = 24,
  SHIPPING = 14,
  SORTING = 10,
  SORTING_BY_ITEM = 23,
  SORTING_BY_ORDER = 22,
  SORTING_WHILE_CHECKING = 11,
  SORTING_WHILE_PICKING = 20,
  STANDARD_PUTAWAY = 5,
  TWO_STEP_TRANSFER_PICKING = 27,
  TWO_STEP_TRANSFER_PUTAWAY_MASS_PUTAWAY = 29,
  TWO_STEP_TRANSFER_PUTAWAY_STANDARD_PUTAWAY = 28,
  WAVE_RUN = 8,
  WHOLE_LOCATION_TRANSFER = 25,
}
export enum NotificationDeviceType {
  PC = 1,
  PDA = 2,
}
export enum NotificationSoundClip {
  SOUND_CLIP_A = 1,
  SOUND_CLIP_B = 2,
  SOUND_CLIP_C = 3,
}
export enum NotificationWarehouseModule {
  INBOUND = 1,
  INVENTORY = 3,
  OUTBOUND = 2,
}
export enum NotificationWorkProcess {
  INBOUND = 1,
  INVENTORY_ADJUSTMENT = 8,
  MT_INBOUND = 2,
  MT_OUTBOUND = 6,
  RACK_TRANSFER = 7,
  RETURN_INBOUND = 3,
  RTS_OUTBOUND = 5,
  SALES_OUTBOUND = 4,
}
export enum OccupiedFlag {
  '/' = 1,
  N = 3,
  UNDEFINED = 0,
  Y = 2,
}
export enum OrderTypeList {
  All = 0,
  Partial_Cancel = 1,
}
export enum OriginalReturnQcReturnReason {
  Did_not_receive_the_order = 1,
  Received_an_incomplete_product_missing_quantity_or_accessories = 103,
  Received_the_wrong_product_wrong_size,
  _wrong_colour,
  _different_product = 2,
  Received_a_product_with_physical_damage_dented,
  _scratched,
  _shattered = 106,
  Received_a_faulty_product_malfunction,
  _does_not_work_as_intended = 107,
  Received_a_counterfeit_product = 105,
  Change_of_mind = 102,
  Product_is_significantly_different_from_description = 4,
  Product_is_used_refurbished_rebuilt = 7,
  Product_does_not_meet_expectations = 104,
  Received_damaged_wrong_product = 101,
  Received_damaged_faulty_product = 3,
  Mutual_agreement_with_the_seller = 5,
  RI_order_has_never_been_created_but_buyer_has_requested_R = 81,
  Buyer_never_request_R_but_WH_still_decide_to_process_offline = 82,
  RI_order_has_never_been_created_failed_delivery = 83,
  Others = 6,
}
export enum OutboundTaskStatus {
  Pending = 0,
  Assigned = 10,
  Ongoing = 1,
  Done = 2,
  Failed = 3,
  Cancel = 9,
}
export enum OutboundTaskType {
  Checking = 2,
  Merging = 7,
  Picking = 1,
  Reverse = 3,
  RtsPicking = 11,
  Sorting = 4,
  SubPicking = 5,
  Transiting = 6,
  TroubleShooting = 10,
}
export enum OutboundTrackingOrderStatus {
  CREATED = 0,
  PENDING_PICK = 9,
  PICKING = 2,
  PICKED = 3,
  CHECKING = 5,
  CHECKED = 6,
  SHIPPING = 7,
  OUTBOUND = 8,
}
export enum OwnerTransferSkuDisplayStatus {
  DONE = 3,
  NOT_EXIST = 4,
  ONGOING = 2,
  PENDING = 1,
}
export enum OwnerTransfertTaskStatus {
  DONE = 3,
  ONGOING = 2,
  PENDING = 1,
}
export enum PackTaskStatus {
  Pending = 0,
  Ongoing = 1,
  Done = 2,
  Cancel = 9,
}
export enum PackagingType {
  PACKAGING_TYPE_BOX = 1,
  PACKAGING_TYPE_ORIGINAL_BOX = 0,
  PACKAGING_TYPE_POUCH = 2,
}
export enum PaperlessFlag {
  Y = 1,
  N = 0,
}
export enum ParcelSplitProcess {
  NOT_PARCEL_SPLIT = 0,
  PARCEL_SPLIT_FINISHED = 3,
  REQUEST_SUCCESS = 2,
  SPLIT_INFO_SAVED = 1,
}
export enum PdaPackageNeedUpgradeType {
  No = 0,
  Yes = 1,
}
export enum PdaPackageStatusType {
  Active = 1,
  Expiry = 2,
  Pending = 0,
}
export enum PdaPackageUpdateModeType {
  Mandatory = 1,
  Optional = 0,
}
export enum PdaTaskCenterType {
  Cycle_Counting_Task = 7,
  Merging_Task = 9,
  Picking_Task = 4,
  Putaway_Task = 3,
  QC_Task = 1,
  Rack_Transfer_Task = 8,
  Receiving_Task = 2,
  Shipping_Task = 6,
  Sorting_Task = 5,
  Transiting_Task = 10,
}
export enum PickOrderType {
  Mass_Order = 1,
  SBS = 0,
}
export enum PickPlanRelationType {
  Pick = 0,
  RTS = 1,
}
export enum PickStatus {
  Pending = 0,
  Assigned = 10,
  Ongoing = 1,
  Done = 2,
  Failed = 3,
  Cancel = 9,
}
export enum PickingAssignmentMode {
  Manual = 2,
  System = 1,
}
export enum PickingPriorityFlag {
  Normal = 2,
  On_hold = 1,
  Priority = 3,
}
export enum PickingTaskBusinessType {
  RTS_Outbound = 1,
  Sales_Outbound = 0,
}
export enum PickingTaskMassOrderFlag {
  Y = 1,
  N = 0,
}
export enum PickupMethod {
  BLOCKED = 3,
  PICKING_LIST = 1,
  TASK = 2,
  UNDEFINED = 0,
}
export enum PickupType {
  MIX_AREA = 3,
  PICKING_AREA = 1,
  STORAGE_AREA = 2,
  UNDEFINED = 0,
}
export enum PrintMethod {
  Batch_Print_In_Order = 1,
  Manual_Input_Printing = 2,
}
export enum PrintPaperType {
  Barcode_Label = 'Barcode Label',
  Invoice = 'Invoice',
  List = 'List',
  Waybill_Label = 'Waybill Label',
}
export enum PrintSettingOrder {
  ASC = 0,
  DESC = 1,
}
export enum PrintSettingOrderKey {
  Mtime = 1,
}
export enum PrintSettingsAvailable {
  Disabled = 0,
  Enabled = 1,
}
export enum PrintSettingsLevelType {
  All = 0,
  Region = 1,
  Warehouse = 2,
}
export enum PrintSettingsPaperType {
  Barcode = 0,
  Label = 1,
  List = 2,
  Waybill = 3,
}
export enum PushSpxStatus {
  Done = 2,
  Ongoing = 1,
  Pending = 0,
}
export enum PutawayOnhold {
  NO = 0,
  YES = 1,
}
export enum PutawayRuleSheetType {
  INBOUND = 0,
  RACK_TRANSFER = 2,
  RETURN_INBOUND = 1,
}
export enum PutawayTaskAssignMode {
  AUTO_ASSIGN = 1,
  MANUAL_ASSIGN = 2,
  SCAN_DEVICE = 3,
}
export enum PutawayTaskAssignRuleCondition {
  IS_BULKY_SKU = 3,
  IS_HEAVY_SKU = 5,
  IS_HIGH_VALUE_SKU = 2,
  IS_OOS_IN_WHS_SKU = 4,
  IS_TEMPERATURE_CONTROLLED_SKU = 1,
  SHOP_LIST = 7,
  SUPPLIER_LIST = 6,
}
export enum PutawayTaskEvent {
  ASSIGNED_BY = 6,
  ASSIGNMENT_MODE = 5,
  DEVICE_TRANSFER = 4,
  IS_ON_HOLD = 3,
  IS_URGENT = 2,
  PUTAWAY_BY = 1,
  STATUS = 7,
}
export enum PutawayTaskOrder {
  ASC = 0,
  DESC = 1,
}
export enum PutawayTaskOrderKey {
  CREATE_TIME = 2,
  TIME_SINCE_CREATION = 1,
  TIME_SINCE_LAST_MODIFIED = 3,
}
export enum PutawayTaskPriority {
  HIGH = 2,
  MEDIUM = 1,
}
export enum PutawayTaskSheetType {
  INBOUND_ORDER = 1,
  MOVE_TRANSFER_INBOUND_RECEIVE_TASK = 4,
  ONE_STEP_RACK_TRANSFER_PICKING_TASK = 5,
  PO_INBOUND_RECEIVE_TASK = 2,
  RETURN_INBOUND_RECEIVE_TASK = 3,
  TWO_SETP_RACK_TRANSFER_PICKING_TASK = 6,
}
export enum PutawayTaskSkuStatus {
  CANCELLED = 90,
  DONE = 80,
  PENDING = 10,
}
export enum PutawayTaskSource {
  MOVE_TRANSFER_INBOUND_RECEIVED = 3,
  ONE_STEP_RACK_TRANSFER_PICKING = 4,
  PO_INBOUND_RECEIVED = 1,
  RETURN_INBOUND_RECEIVED = 2,
  TWO_SETP_RACK_TRANSFER_PICKING = 5,
}
export enum PutawayTaskStatus {
  PENDING = 10,
  ASSIGNED = 100,
  ONGOING = 20,
  DONE = 80,
}
export enum PutawayTaskType {
  MOVE_TRANSFER_INBOUND = 3,
  PO_INBOUND = 1,
  RETURN_INBOUND = 2,
  THREE_SETP_RACK_TRANSFER_PUTAWAY = 7,
  THREE_SETP_RACK_TRANSFER_PUTBACK = 8,
  TWOSETP_RACK_TRANSFER = 5,
}
export enum PutawayType {
  ALL = 3,
  MASS_PUTAWAY = 1,
  STANDARD_PUTAWAY = 2,
}
export enum PutawayUrgent {
  NO = 0,
  YES = 1,
}
export enum QCRuleType {
  AQL = 3,
  PERCENTAGE__BASED = 2,
  SKU__ATTRIBUTES = 1,
}
export enum QcMoveTransferInboundDamageType {
  Damaged = 3,
  Expired = 2,
  Expiring = 1,
}
export enum QcPoInboundDamageType {
  Shopee_Rejected_Other_Issues = 5,
  Supplier_QC_Failed_Abnormal_Item = 4,
  Supplier_QC_Failed_Damaged = 3,
  Supplier_QC_Failed_Expired = 1,
  Supplier_QC_Failed_Expiring = 2,
  Supplier_Rejected_Other_Issues = 6,
}
export enum QcReturnInboundDamageType {
  Cannot_QC = 1,
  Expired = 4,
  Expiring = 3,
  Manufacturing_Defect = 2,
  Physical_Damage_3PL_Error = 6,
  Physical_Damage_WH_Error = 5,
  Physical_Damage_WH_3PL_Error = 7,
  Wrong_description = 8,
}
export enum QcTaskAssignMode {
  Auto_Assign = 1,
  Manual_Assign = 2,
}
export enum QcTaskModifyLogEvent {
  Status = 1,
}
export enum QcTaskOnHold {
  NO = 0,
  YES = 1,
}
export enum QcTaskOrder {
  ASC = 0,
  DESC = 1,
}
export enum QcTaskOrderKey {
  CreateTime = 2,
  TimeSinceCreation = 1,
  TimeSinceLastModified = 3,
}
export enum QcTaskPriority {
  HIGH = 2,
  MEDIUM = 1,
}
export enum QcTaskSheetType {
  Move_Transfer_Inbound_Arriving = 3,
  Po_Inbound_Counting = 1,
  Return_Inbound_Arriving = 2,
}
export enum QcTaskStatus {
  PENDING = 10,
  ASSIGNED = 100,
  ONGOING = 20,
  DONE = 80,
  CANCELLED = 90,
}
export enum QcTaskType {
  Move_Transfer_Inbound = 3,
  Po_Inbound = 1,
  Return_Inbound = 2,
}
export enum QcTaskUrgent {
  NO = 0,
  YES = 1,
}
export enum RTExceptionDealMethod {
  CONFIRM_LOST = 1,
  CREATE_RT_ORDER = 2,
}
export enum RTExceptionDealTaskType {
  INVENTORY_ADJUST = 1,
  RACK_TRANSFER = 2,
}
export enum RTExceptionSkuStatus {
  CANCEL = 4,
  DONE = 3,
  ONGOING = 2,
  PENDING = 1,
}
export enum RTExceptionSkuSubStatus {
  CANCEL = 4,
  DONE = 3,
  LOST = 1,
  ONGOING = 2,
}
export enum RTExceptionSkuUidStatus {
  N = 2,
  Y = 1,
}
export enum RTSAllocateMode {
  Locked_Mode = 1,
  No_Allocate = 0,
  Pre_Picked_Mode = 2,
}
export enum RTSCreateChannel {
  PMS = 1,
  WMS = 0,
}
export enum RTSDamageTYpe {
  DAMAGED = 1,
  EXPIRED = 2,
}
export enum RTSDeliveryMethod {
  Pick_Up_By_Supplier_During_Inbound = 2,
  Pick_Up_By_Suppliers = 1,
  Shopee_Sends_Back = 3,
  Throw_Away = 4,
}
export enum RTSOrderStatus {
  Pending = 7,
  Allocated = 11,
  Approving = 9,
  Approved = 10,
  Rejected = 8,
  Pending_Pick = 12,
  Picking = 2,
  Picked = 3,
  Shipping = 13,
  Outbound = 6,
  Canceled = 1,
}
export enum RTSPrintTag {
  Y = 1,
  N = 0,
}
export enum RTSReasonType {
  AGING = 'R2',
  BUYER_RETURN = 'R5',
  DAMAGED__EXPIRY = 'R9',
  DAMAGED_ITEMS = 'RP1',
  EXPIRED = 'R4',
  EXPIRING = 'R7',
  MANUFACTURING_DEFECTIVE = 'R3',
  OTHERS = 'R6,VR-IB1,VR-IB2,VR-IB3',
  SELLER_REQUEST = 'R8',
  SUPPLIER_REQUEST = 'R1',
}
export enum RTSType {
  Real_RTS = 20,
  Replacement = 50,
  Virtual_RTS_With_ReInbound = 30,
}
export enum RackTransferAssignmentMode {
  AUTO_ASSIGNED = 2,
  MANUAL_ASSIGNED = 3,
  SCAN_TASK = 1,
}
export enum RackTransferExportStatus {
  Created = 1,
  Pending = 2,
  Locked = 3,
  OnGoing = 4,
  Done = 5,
  Cancel = 6,
  Incomplete = 7,
}
export enum RackTransferOneStepSubTaskStatus {
  CANCEL = 5,
  DONE = 4,
  LOCKED = 2,
  ONGOING = 3,
  PENDING = 1,
}
export enum RackTransferTaskAssignStatus {
  ASSIGNED_TO_ME = 0,
  UNASSIGNED = 1,
}
export enum RackTransferTaskBlockInv {
  UNDEFINE = 0,
  N = 2,
  Y = 1,
}
export enum RackTransferTaskDevice {
  PC = 1,
  PDA = 2,
}
export enum RackTransferTaskFrom {
  MANUAL_CREATION = 1,
  OB_TROUBLESHOOT = 6,
  PREHIT_RE_ARRANGEMENT = 8,
  REARRANGEMENT_REPORT = 4,
  REPLENISHMENT = 9,
  REPLENISHMENT_REPORT = 2,
  REVERSE = 10,
  RT_TROUBLESHOOT = 5,
  SURPLUS_BALANCE = 7,
  UPLOAD = 3,
}
export enum RackTransferTaskIsOnHold {
  NO = 0,
  YES = 1,
}
export enum RackTransferTaskIsUrgent {
  NO = 0,
  YES = 1,
}
export enum RackTransferTaskMethod {
  PARTIAL_LOCATION = 2,
  WHOLE_LOCATION = 1,
}
export enum RackTransferTaskPriority {
  HIGH = 1,
  NORMAL = 0,
}
export enum RackTransferTaskStatus {
  ASSIGNED = 8,
  CANCEL = 6,
  CREATED = 1,
  DONE = 5,
  INCOMPLETE = 7,
  LOCKED = 3,
  ON_GOING = 4,
  PENDING = 2,
  PENDING_PUTAWAY = 11,
  PENDING_PUTBACK = 13,
  PICKED = 10,
  PICKING = 9,
  PUTAWAY = 12,
  PUTBACK = 14,
}
export enum RackTransferTaskType {
  ONE_STEP = 1,
  TWO_STEP = 2,
}
export enum RackTransferDeviceSubTaskStatus {
  DONE = 4,
  PICKED = 2,
  PICKING = 1,
  PUTTING = 3,
}
export enum RackTransferTwoStepPickingTaskStatus {
  CANCEL = 4,
  DONE = 3,
  ONGOING = 2,
  PENDING = 1,
}
export enum RackTransferTwoStepStage {
  TWO_STEP_PICKING = 1,
  TWO_STEP_PUTAWAY = 2,
}
export enum RackTransferTwoStepSubTaskStatus {
  CANCEL = 7,
  DONE = 6,
  LOCKED = 2,
  PENDING = 1,
  PICKED = 4,
  PICKING = 3,
  PUTTING = 5,
}
export enum RackTransferWholeLocationSubTaskStatus {
  DONE = 2,
  ONGOING = 1,
}
export enum ReQcType {
  Change_SKU_quality = 1,
  Change_SKU_quantity = 2,
  Others = 3,
}
export enum RearrangementTaskStatus {
  CREATED = 0,
  DONE = 5,
  FAILED = 6,
  NODETAIL = 2,
  PARTIAL__CREATION = 4,
  PENDING = 3,
  RUNNING = 1,
}
export enum RearrangementTaskType {
  MIX__SKU__CONSOLIDATE = 2,
  SAME__SKU__CONSOLIDATE = 1,
  WRONG__ABC = 4,
  WRONG__ACTIVITY__TYPE = 5,
  WRONG__BATCH = 6,
  WRONG__SUPER__A = 3,
}
export enum ReceiveTaskAssignMode {
  AUTO_ASSIGN = 1,
  MANUAL_ASSIGN = 2,
}
export enum ReceiveTaskModifyLogEvent {
  STATUS = 2,
}
export enum ReceiveTaskOrder {
  ASC = 0,
  DESC = 1,
}
export enum ReceiveTaskOrderKey {
  CREATE_TIME = 2,
  TIME_SINCE_CREATION = 1,
  TIME_SINCE_LAST_MODIFIED = 3,
}
export enum ReceiveTaskStatus {
  PENDING = 10,
  ASSIGNED = 100,
  ONGOING = 20,
  DONE = 80,
  CANCELLED = 90,
}
export enum RecordingUnit {
  BOXES = 2,
  DRUMS = 5,
  PACKS = 4,
  PCS = 1,
  ROLLS = 3,
  SETS = 7,
  UNITS = 6,
}
export enum RefreshPlanQtyCalculateStrategy {
  AVAILABLE_STOCK = 1,
  OOS_IN_PICKING_ONLY = 2,
  TARGET_STOCK = 3,
  URGENT_OOS_IN_PICKING_ONLY = 4,
}
export enum RefreshOrderByStrategy {
  MINIMUM_REPL_QTY = 1,
  OOS_IN_PICKING_ORDERS = 2,
  PLAN_REPL_QTY = 3,
  PICKING_SEQUENCE = 4,
}
export enum RefreshOrderByField {
  DEFAULT = 0,
  OOS_IN_PICKING_ORDER = 1,
}
export enum RefreshOrderByType {
  ASC = 1,
  DESC = 0,
}
export enum ReplPanIsOosInPicking {
  N = 0,
  Y = 1,
}
export enum ReplPanIsReverse {
  N = 0,
  Y = 1,
}
export enum ReplPanIsUrgent {
  N = 0,
  Y = 1,
}
export enum ReplPlanStatus {
  CREATED = 1,
  PENDING = 2,
  LOCKED = 3,
  ONGOING = 4,
  DONE = 5,
}
export enum ReplReverseConfigRule {
  DEFAULT_RULE = 'RI0005',
  LEAST_QUANTITY_LOCATION = 'RI0004',
  LEFO = 'RI0001',
  LIFO = 'RI0002',
  REVERSE_PRIORITIZED_ZONE_GROUP = 'RI0003',
}
export enum ReplReverseRuleSwitchStatus {
  N = 0,
  Y = 1,
}
export enum ReplRunningTaskStatus {
  DONE = 2,
  FAIL = 3,
  RUNNING = 1,
}
export enum ReplRunningTaskType {
  REPL_ADD_TO_ORDER = 1,
  REPL_CREATE_RUN_WAVE = 2,
  REPL_REFRESH = 3,
}
export enum ReplWaveStatus {
  CANCEL = 4,
  DONE = 3,
  ONGOING = 2,
  PENDING = 1,
}
export enum ReplRunWaveViewABCClassification {
  A = 1,
  B = 2,
  C = 3,
  EXCLUDED = 5,
  SA = 4,
}
export enum ReplenishmentForecastSalesType {
  CAMPAIGN__SALES = 2,
  FLASH__SALES = 1,
  ORGANIC__SALES = 3,
  UNDEFINED = 0,
}
export enum ReplenishmentNeeded {
  NO = 0,
  YES = 1,
}
export enum ReplenishmentSalesTypeNew {
  CAMPAIGN__SALES = 1,
  FLASH__SALES = 10,
  ORGANIC__SALES = 100,
}
export enum ReportingTeam {
  INBOUND = 2,
  INVENTORY = 3,
  MOVE__TRANSFER = 6,
  OTHERS = 7,
  OUTBOUND = 1,
  RETURN__INBOUND = 5,
  RTS = 4,
}
export enum ReprocessTaskType {
  LABELLING = 5,
  BUNDLING = 6,
  REPACKAGING = 7,
}
export enum ReturnInboundType {
  BUYER_RETURN = 1,
  FAILED_DELIVERY = 2,
}
export enum ReturnQcCheckList {
  Complete_Product = 1,
  Tags__Labels_Attached = 2,
  Unused_Product = 3,
  Sealed = 4,
}
export enum ReturnQcItemHandling {
  Return_Accepted__3PL_claimed = 1,
  Return_Accepted__Reinbound_to_WH = 2,
  Return_Accepted__Return_to_Seller = 3,
  Return_Accepted__3PL_Claim_fail,
  _Reinbound_to_WH = 4,
  Return_Accepted__Replacement = 5,
  Return_Rejected__Return_to_Buyer = 6,
}
export enum ReturnQcReturnReason {
  Did_not_receive_the_order = 1,
  Received_an_incomplete_product_missing_quantity_or_accessories = 103,
  Received_the_wrong_product_wrong_size,
  _wrong_colour,
  _different_product = 2,
  Received_a_product_with_physical_damage_dented,
  _scratched,
  _shattered = 106,
  Received_a_faulty_product_malfunction,
  _does_not_work_as_intended = 107,
  Received_a_counterfeit_product = 105,
  Change_of_mind = 102,
  Product_is_significantly_different_from_description = 4,
  Product_is_used_refurbished_rebuilt = 7,
  Product_does_not_meet_expectations = 104,
  Others = 6,
  Received_damaged_wrong_product = 101,
  Received_damaged_faulty_product = 3,
  Mutual_agreement_with_the_seller = 5,
}
export enum ReturnQcSkuType {
  GOOD = 1,
  DAMAGE = 2,
  ABNORMAL = 3,
}
export enum ReturnReason {
  RI_order_has_never_been_created_but_buyer_has_requested_R = 81,
  Buyer_never_request_R_but_WH_still_decide_to_process_offline = 82,
  RI_order_has_never_been_created_failed_delivery = 83,
  Others = 6,
}
export enum ReverseSkuStatus {
  CREATED = 0,
  RETURNED = 1,
}
export enum ReverseSourceType {
  SOURCE_CHECKING_TASK = 4,
  SOURCE_ORDER = 1,
  SOURCE_PICKING_TASK = 2,
  SOURCE_SORTING_TASK = 3,
  SOURCE_TBS_TASK = 5,
}
export enum ReverseTaskStatus {
  DONE = 2,
  ONGOING = 1,
  PENDING = 0,
}
export enum ReverseTaskStatusList {
  Pending = 0,
  Ongoing = 1,
  Done = 2,
  Cancel = 9,
}
export enum RtsPickingTaskStatus {
  CANCEL = 9,
  DONE = 2,
  ONGOING = 1,
  PENDING = 0,
}
export enum RtsQuotaStatus {
  OFF = 0,
  ON = 1,
}
export enum RtsShipStatus {
  DONE = 2,
  ONGOING = 1,
  PENDING = 0,
  SHIPPING = 3,
}
export enum SKUBlockType {
  EXPIRED = 3,
  EXPIRING = 1,
  NORMAL = 0,
}
export enum SKUQualityStatus {
  DAMAGE = 1,
  GOOD = 0,
}
export enum SKUUnitOrderStatusType {
  CHECKED = 2,
  CREATED = 0,
  SHIPPED = 3,
  STORED = 1,
}
export enum SKUUnitStatusType {
  INVALID = 2,
  MISSING = 3,
  SHIPPED = 5,
  STORAGE_UNUSED = 4,
  UNUSED = 0,
  USED = 1,
}
export enum SalesOrderExportStype {
  Partial_Cancel = 1,
  Split_Parcel = 0,
}
export enum SalesOrderMassOrderFlag {
  Y = 1,
  N = 0,
}
export enum SalesOrderMixShopFlag {
  MIX_SHOPS = 1,
  SINGLE_SHOP = 0,
}
export enum SalesOrderPendingOTFlag {
  Y = 1,
  N = 0,
}
export enum SalesOrderSbsFlag {
  Y = 1,
  N = 0,
}
export enum SalesOrderStatus {
  Created = 0,
  Pending_Pick = 9,
  Picking = 2,
  Picked = 3,
  Pick_Fail = 4,
  Checking = 5,
  Checked = 6,
  Sorting = 12,
  Sorted = 13,
  Packing = 10,
  Packed = 11,
  Shipping = 7,
  Outbound = 8,
  Cancel = 1,
}
export enum SalesOrderUrgentFlag {
  Y = 1,
  N = 0,
}
export enum SalesPickingMethod {
  UNDEFINE = 0,
  Batch_Picking = 1,
  Sorting_While_Picking = 2,
}
export enum SalesPickingScanMethod {
  PDA = 2,
  UNKNOWN = 0,
  WEB = 1,
}
export enum SalesPickingTaskCreateType {
  Pick_To_Order = 0,
  Pick_To_Wave = 1,
}
export enum ShiftRepeatType {
  ALL_DAY = 0,
  WEEKDAY = 1,
  WEEKEND = 2,
}
export enum ShipOrderStatus {
  DONE = 1,
  SHIPPING = 0,
}
export enum ShipTaskStatus {
  Pending = 0,
  Ongoing = 1,
  Done = 2,
}
export enum ShipTaskType {
  PC = 1,
  PDA = 2,
}
export enum ShopCbOptionType {
  CB = 1,
  LOCAL = 0,
}
export enum ShopSkuSyncStatusType {
  SHOPID_INVALID = 2,
  SHOPID_SYNCING = 3,
  SKUS_NOT_SYNCED = 0,
  SKUS_SYNC_SUCCESSFUL = 1,
}
export enum ShopSnMgtType {
  NO = 0,
  YES = 1,
}
export enum ShopStatusType {
  BANNED = 2,
  DELETED = 0,
  NORMAL = 1,
}
export enum SkuBatchInfoRuleDetailInputControl {
  OPTIONAL = 3,
  REQUIRED = 1,
  SYNC_FROM_PMS = 4,
}
export enum SkuBatchInfoRuleInputControl {
  OPTIONAL = 3,
  REQUIRED = 1,
}
export enum SkuBatchOptionalValue {
  GOOD_DAMAGE = 'Good,Damage',
  YES_NO = 'Yes,No',
}
export enum SkuDuringBlackOutPeriod {
  ACCUMULATED = 0,
  EXEMPTED = 1,
}
export enum SkuIsSnMgtFlag {
  N = 0,
  Y = 1,
}
export enum SkuItemStatus {
  BLOCKED = 2,
  DELETE = 0,
  NORMAL = 1,
}
export enum SkuLockRelativeType {
  BLOCK = 8,
  CYCLE_COUNT = 9,
  INVENTORY_ADJUST = 7,
  MOVE_ORDER = 10,
  MOVE_TRANSFER = 6,
  OUTBOUND_CHECKED = 3,
  OUTBOUND_PICKING = 5,
  RACK_TRANSFER = 2,
  RETURN_ORDER_PACKED = 4,
  SKU_CHECK = 1,
}
export enum SkuQualityType {
  DAMAGE = 1,
  GOOD = 0,
}
export enum SkuRuleCategory {
  BATCH_INFO_RULE = 2,
  UNIT_ID_RULE = 1,
}
export enum SkuRuleStatusType {
  N = 0,
  Y = 1,
}
export enum SkuSellType {
  INVALID_SELLING_TYPE = 0,
  PCS = 2,
  PACK = 3,
  CARTON = 1,
  UNKNOWN = 4,
}
export enum SkuSizeType {
  BULKY_ITEM = 10,
  EXTRA_BULKY_ITEM = 20,
  NON_BULKY_ITEM = 0,
}
export enum SkuSizeTypeMode {
  LONGEST_SIDE = 0,
  SUM__LWH = 1,
}
export enum SkuWeightType {
  HEAVY_ITEM = 1,
  NON_HEAVY_ITEM = 0,
}
export enum SortByItemNextProcess {
  ABNORMALSUMMARY = 5,
  FINISH = 4,
  INCOMPLETE = 3,
  PUTREMAINITEMTOTS = 6,
  SCANSKU = 1,
  TROUBLESHOOTING = 2,
}
export enum SortByOrderGridStatus {
  ABNORMAL = 1,
  NORMAL = 0,
}
export enum SortByOrderNextProcess {
  BINDBASKET = 0,
  COMPLETE = 2,
  GETSORTINGINFO = 1,
}
export enum SortingOrderBasketSortStatus {
  COMPLETE = 1,
  INCOMPLETE = 3,
  ONGOING = 0,
}
export enum SortingTaskSortingMethod {
  Merging_While_Sorting = 6,
  PC_Sorting = 1,
  PDA_Sorting_by_Item = 3,
  PDA_Sorting_by_Order = 2,
}
export enum SortingTaskStatus {
  Pending = 0,
  Ongoing = 1,
  Done = 2,
  Cancel = 9,
}
export enum StagingLocationType {
  ALL = 0,
  BULKY = 5,
  HEAVY = 3,
  HV_HIGH_VALUE = 2,
  TC_TEMPERATURE_CONTROL = 4,
  URGENT = 1,
}
export enum StationGridStatus {
  NORMAL = 0,
  USING = 1,
}
export enum StationProcess {
  CHECKING = 0,
  CHECKING_AND_PACKING = 4,
  MERGE_LANE = 7,
  MERGING_WHILE_SORTING = 3,
  PACKING = 2,
  SORTING_WHILE_CHECKING = 1,
  SORTING = 5,
  PRE_SORTING = 9,
}

export enum StationProcessInbound {
  BOXING = 11,
  QC = 10,
}

export enum StationSettingKey {
  RECORD_CHECKING_STATION_ID = 0,
  RECORD_PACKING_STATION_ID = 2,
  RECORD_SORTING_WHILE_CHECKING_STATION_ID = 1,
}
export enum StationStatus {
  NORMAL = 0,
  USING = 1,
}
export enum StationType {
  SALES_OUTBOUND = 0,
  MOVE_TRANSFER = 1,
  INBOUND = 2,
}
export enum StationEventType {
  CREATE = 0,
  DELETE = 4,
  EDIT = 1,
  ENABLE_DISABLE = 2,
  REMOVE_WEIGHT_VALIDATION = 3,
}
export enum StorageType {
  DRIVE_IN_RACK = 6,
  GROUND_STACKED = 3,
  HEAVY_RACK = 2,
  LIGHT_RACK = 5,
  ROLLING_RACK = 4,
  UNDEFINED = 0,
  VAN = 1,
}
export enum SupplierSkuSourcingStatusType {
  AVAILABLE = 0,
  CATEGORY_MANAGED = 6,
  DISCONTINUED = 2,
  DO_NOT_ORDER = 3,
  OUT_OF_STOCK = 1,
  OVERSTOCK = 5,
  PRE_SALE = 4,
}
export enum SupplierType {
  B2B2C = 'B2B2C',
  B2C = 'B2C',
  CB = 'CB',
  Consignment = 'Consignment',
  SBS_Supplier = 'SBS Supplier',
}
export enum SupplierVendorType {
  CONSIGNMENT = 2,
  FULFILLMENT = 0,
  OUTRIGHT = 1,
}
export enum SyncZktStatus {
  UNSYNCHRONIZED = 0,
  SYNC_SUCCESSFULLY = 3,
  CREATED_FAILED = 1,
  SYNC_WHS_FAILED = 2,
}
export enum TaskTrackingTimeType {
  TODAY = 1,
  LAST24_HOURS = 2,
  WEEK = 3,
}
export enum TbsTaskStatus {
  ADD_PICKING = 4,
  COUNTED = 3,
  DONE = 10,
  ONGOING = 2,
  PENDING = 1,
  REVERSE_PICKING = 5,
}
export enum TbsTaskType {
  IN_INVENTORY = 1,
  WHILE_HANDLING = 2,
  TAKEOVER = 3,
}
export enum TimeZoneType {
  INDONESIA_STANDARD_TIME = 1,
  THAILAND_STANDARD_TIME = 0,
  VIETNAM_STANDARD_TIME = 2,
  MALAYSIA_STANDARD_TIME = 4,
  PHILIPPINE_STANDARD_TIME = 5,
  SINGAPORE_STANDARD_TIME = 6,
  TAIPEI_STANDARD_TIME = 3,
}
export enum TopPickingAuditCountBase {
  LOCATION_BASED = 0,
  SKU_BASED = 1,
}
export enum TopPutawayAuditCountBase {
  LOCATION_BASED = 0,
  SKU_BASED = 1,
}
export enum TopRackTransferAuditCountBase {
  LOCATION_BASED = 0,
  SKU_BASED = 1,
}
export enum TrInboundDeviceDisplayStatus {
  USED = 1,
  NORMAL = 2,
}
export enum TransactionDirectFlagType {
  CR = 2,
  DR = 1,
}
export enum TransactionSheetType {
  BATCH__TRANSFER = 12,
  BLOCK__INVENTORY = 13,
  CYCLE__COUNT = 5,
  INBOUND__ORDER = 1,
  INVENTORY__ADJUST = 9,
  MOVE__ORDER = 15,
  MOVE__ORDER__INBOUND = 7,
  MOVE__ORDER__OUTBOUND = 8,
  MOVE__TRANSFER__INBOUND = 10,
  MOVE__TRANSFER__OUTBOUND = 11,
  MTI__ARRIVAL = 14,
  OUTBOUND__ORDER = 2,
  RACK__TRANSFER = 3,
  RETURN__INBOUND__ORDER = 6,
  RETURN__ORDER = 4,
}
export enum TransactionType {
  ADJUST__DECREASE = 12,
  ADJUST__INCREASE = 13,
  BATCH__TRANSFER = 15,
  MOVE__INBOUND = 20,
  MOVE__OUTBOUND = 21,
  OVERAGE = 5,
  PICKING = 8,
  PUT__AWAY = 1,
  RACK__TRANSFER = 3,
  RACK__TRANSFER__CYCLECOUNT = 10,
  RECEIVED = 7,
  RECEIVED__MODIFY = 14,
  RETURN__TO__SUPPLIER = 4,
  REVERSE = 9,
  RE__CHECKING = 11,
  RE__RECEIVED = 22,
  SHIP__ORDER = 2,
  SHORTAGE = 6,
}
export enum TransitingStatus {
  Pending = 0,
  Ongoing = 1,
  Done = 2,
  Cancel = 9,
}
export enum TransitingTaskStatus {
  Pending = 0,
  Ongoing = 1,
  Done = 2,
  Cancel = 9,
}
export enum TurnoverRuleRuleType {
  MT__OUTBOUND = 2,
  RTS__OUTBOUND = 1,
  SALES__OUTBOUND = 0,
}
export enum UIDRuleType {
  Only_WMS_generated_UID_Allowed = 0,
  User_defined = 1,
}
export enum UserAccessStatusType {
  ABNORMAL = 100,
  NORMAL = 0,
}
export enum UserActiveLevelType {
  DAYS_WITHIN15 = 1,
  DAYS_WITHIN16_TO30 = 2,
  DAYS_WITHIN31_TO60 = 3,
  DAYS_WITHIN61_TO90 = 4,
  DAYS_BEFORE91 = 5,
}
export enum UserDefinedType {
  Allow_all_characters = 1,
  Allow_letters_and_numbers = 0,
  Allow_letters,
  _numbers_and_specified_characters = 2,
}
export enum UserSoupStatusType {
  DELETE = 100,
  NORMAL = 0,
}
export enum UserSourcingFlagType {
  OWN = 1,
  OUTSOURCING = 5,
}
export enum UserStatusType {
  DELETE = 100,
  NORMAL = 0,
}
export enum WarehouseCategory {
  CROSS_BORDER = 1,
  LOCAL = 0,
}
export enum WarehouseFlags {
  BATCH_INBOUND = 4,
  NO_SKU_LABEL = 2,
  NO_UNIT = 1,
  WITH_UNIT = 0,
}
export enum WaveRuleType {
  Single_SKU_Single_QTY = 1,
  Single_SKU_Any_QTY = 4,
  Mix_SKU_Single_QTY = 5,
  Same_SKU_Same_QTY = 2,
  Mix_SKU_Any_QTY = 3,
}
export enum WaveStatus {
  PENDING = 0,
  DONE = 3,
  CANCELING = 4,
  CANCEL = 5,
  FAILED = 9,
}
export enum WeightValidationOperation {
  PROCEED = 1,
  RE__CHECKING = 2,
}
export enum WmsV2Module {
  INBOUND = 1,
  OUTBOUND = 2,
  INVENTORY = 3,
}
export enum ZoneActivityType {
  DAMAGE = 3,
  HIGH_VALUE = 6,
  NORMAL = 2,
  RETURN = 4,
  UNDEFINED = 0,
}
export enum ZoneLimitOptionType {
  Purchase_Type = 1,
}
export enum ZoneLimitRuleFieldType {
  CONSIGNMENT = 4,
  DAMAGED = 1,
  GOOD = 0,
  HIGH_VALUE = 3,
  OUTRIGHT = 2,
  SKUTEMP_COLD = 7,
  SKUTEMP_CONSTANT = 6,
  SKUTEMP_FREEZING = 8,
  SKUTEMP_NORMAL = 5,
}
export enum ZoneLimitRuleType {
  PURCHASE_TYPE = 1,
  SKU_HIGH_VALUE = 2,
  SKU_QUALITY = 0,
  SKU_TEMPERATURE = 3,
}
export enum ZoneOptionType {
  Consignment = 1,
  Fulfillment = 2,
  Outright = 0,
}
export enum ZoneSoldoutType {
  BULK = 3,
  CASE = 2,
  PALLET = 1,
  UNDEFINED = 0,
}
export enum ZoneStorageAttr {
  UNDEFINED = 0,
  WORKING_AREA = 1,
}

export enum FSCOrderIsDiff {
  '-' = 2,
  'N' = 0,
  'Y' = 1,
}
export enum FSCOrderSourceFrom {
  FULL_STOCK_COUNT = 4,
}
export enum FSCOrderStatus {
  PENDING = 1,
  ONGOING = 2,
  DONE = 4,
  CANCEL = 3,
}
export enum FSCOrderType {
  LOCATION_CC = 2,
}
export enum FSCSplittingType {
  ZONE = 1,
  PATHWAY = 2,
  SEGMENT_OF_PATHWAY = 3,
}
export enum FSCTaskStage {
  '01ST_COUNT' = 1,
  '02ND_COUNT' = 2,
  '03RD_COUNT' = 3,
  '04TH_COUNT' = 4,
  '05TH_COUNT' = 5,
  '06TH_COUNT' = 6,
  '07TH_COUNT' = 7,
  '08TH_COUNT' = 8,
  '09TH_COUNT' = 9,
  '10TH_COUNT' = 10,
}
export enum FSCTaskStatusForExport {
  CANCEL = 3,
  DONE = 4,
}
export enum FSCTaskStatus {
  ASSIGNED = 5,
  CANCEL = 3,
  DONE = 4,
  ONGOING = 2,
  PENDING = 1,
}

export enum FSCCreateStatus {
  'FSC_CANCELLED_DONE' = 5,
  'FSC_CREATE_CANCELLING' = 4,
  'FSC_CREATE_DONE' = 2,
  'FSC_CREATE_FAIL' = 3,
  'FSC_CREATE_RUNNING' = 1,
  'FSC_CREATE_UNDEFINED' = 0,
}

export enum FSCTaskCompleteStatus {
  COMPLETING = 1,
  FAIL = 3,
  INITIAL = 0,
  SUCCESS = 2,
}

export enum SKUBusinessType {
  FBS = 2,
  FRS = 1,
  NORMAL_RETAIL = 3,
  SCS = 4,
}

/** 打印类型 */
export enum PrintType {
  waybill = 0,
  Exception = 1,
  DANFE = 2,
}
export enum Judge {
  Truthy = 1,
  Falsy = 0,
}
