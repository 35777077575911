export default [
  {
    path: 'organizationstructure',
    name: 'laborManagement.organizationstructure',
    meta: {
      title: 'Organization Structure',
      filterSave: true,
    },
    redirect: '/wms-labormanagement/organizationstructure',
    // component: () =>
    //   import('@/views/labor-management/organization-structure/index.vue'),
  },
];
