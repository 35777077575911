export default [
  {
    path: 'managestaff',
    name: 'laborManagement.manageStaff',
    meta: {
      title: 'Manage Staff',
      filterSave: true,
    },
    redirect: '/wms-labormanagement/managestaff',
    // component: () => import('@/views/labor-management/manage-staff/index.vue'),
  },
  // {
  //   path: 'managestaff/create',
  //   name: 'laborManagement.manageStaff.create',
  //   meta: {
  //     title: 'Create New Staff',
  //     menuHide: true,
  //     navList: [
  //       {
  //         routeName: 'laborManagement',
  //         title: 'Labor Management',
  //       },
  //       {
  //         routeName: 'laborManagement.manageStaff',
  //         title: 'Manage Staff',
  //       },
  //     ],
  //   },
  //   exact: true,
  //   component: () => import('@/views/labor-management/manage-staff/view'),
  // },
  {
    path: 'managestaff/:staffNo/view',
    name: 'laborManagement.manageStaff.detail',
    meta: {
      title: 'Staff Detail',
      menuHide: true,
      navList: [
        {
          routeName: 'laborManagement',
          title: 'Workforce Management',
        },
        {
          routeName: 'laborManagement.manageStaff',
          title: 'Manage Staff',
        },
      ],
    },
    exact: true,
    redirect: '/wms-labormanagement/managestaff/:staffNo/view',
    // component: () => import('@/views/labor-management/manage-staff/view'),
  },
  // {
  //   path: 'managestaff/:staffNo/edit',
  //   name: 'laborManagement.manageStaff.edit',
  //   meta: {
  //     title: 'Edit Staff',
  //     menuHide: true,
  //     navList: [
  //       {
  //         routeName: 'laborManagement',
  //         title: 'Workforce Management',
  //       },
  //       {
  //         routeName: 'laborManagement.manageStaff',
  //         title: 'Manage Staff',
  //       },
  //     ],
  //   },
  //   exact: true,
  //   component: () => import('@/views/labor-management/manage-staff/view'),
  // },
];
