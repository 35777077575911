import organizationStructure from './organization-structure';
import manageStaff from './manage-staff';
import recording from './recording';
import actList from './activity-list';
import scanLog from './scan-log';
import { IS_CN } from '@/utils/tools';

export default {
  path: '/labormanagement',
  name: 'laborManagement',
  meta: {
    title: 'Workforce Management',
    icon: 'Labor Management',
  },
  redirect: '/wms-labormanagement/managestaff',
  // component: () => import('@/views/layout/index.vue'),
  children: [
    ...(IS_CN ? organizationStructure : []),
    ...manageStaff,
    ...recording,
    ...(IS_CN ? [] : actList),
    ...scanLog,
  ],
};
