var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"app"}},[(
      _vm.$route.path.includes('linehaul') ||
      _vm.$route.path.includes('cctv-management') ||
      _vm.$route.path.includes('wms-labormanagement') ||
      _vm.$route.path.includes('wms-dashboard') ||
      _vm.$route.path.includes('workforce')
    )?_c('Layout'):_c('router-view'),_c('wms-portal-target',{attrs:{"name":"root"}}),_c('RemoteHostSetting')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }