import { DataCollectorInstance, TransactionCollector } from '@scfe-common/core';
import { handleResponseData } from '../../utils';
import { ActionType, RequestState } from '@scfe-common/chain-tracker';
import { CustomActionType } from '@/utils/chain-tracker/plugins/types';

export default [
  {
    name: 'SLASaleOutboundCheckingChain',
    status: {
      skuScanErrorCount: 0,
      innerPackingScanErrorCount: 0,
      outPackingScanErrorCount: 0,
      errorCount: 0,
    },

    // 任务信息
    taskInfo: {
      /**
       * 是否需要扫描内包材
       */
      need_scan_inner_packaging: false,
      /**
       * 是否需要扫描外包材
       */
      need_scan_outer_packaging: false,
      /**
       * 是否需要移除重量输入
       */
      remove_weight_input: false,
      /**
       * 波次类型
       */
      wave_type: 0,
      /**
       * 是否已经开始扫描 SKU
       */
      isAllSKUScanStart: false,
    },
    collector: {} as TransactionCollector,

    // 耗时埋点
    /**
     * 全流程耗时
     */
    ALLName: 'custom_SaleOutboundChecking',
    /**
     * 单个扫描 SKU 耗时
     */
    ScanSKU: 'custom_SaleOutboundChecking_scan_SKU',
    /**
     * 全流程扫描 SKU 耗时
     */
    ScanSKUAll: 'custom_SaleOutboundChecking_scan_SKU_All',
    /**
     * 扫描内包材耗时
     */
    ScanInnerPacking: 'custom_SaleOutboundChecking_scan_InnerPacking',
    /**
     * 全流程扫描内包材耗时
     */
    ScanInnerPackingAll: 'custom_SaleOutboundChecking_scan_InnerPacking_All',
    /**
     * 扫描外包材耗时
     */
    ScanOutPacking: 'custom_SaleOutboundChecking_scan_OutPacking',
    /**
     * 输入重量耗时
     */
    ScanWeight: 'custom_SaleOutboundChecking_scan_Weight',

    // 新增各阶段错误数量埋点
    /**
     * SKU 扫描阶段错误数量
     */
    SKUScanErrorCount: 'custom_SaleOutboundChecking_sku_scan_error_count',
    /**
     * 内包材扫描阶段错误数量
     */
    InnerPackingScanErrorCount:
      'custom_SaleOutboundChecking_inner_packing_scan_error_count',
    /**
     * 外包材扫描阶段错误数量
     */
    OutPackingScanErrorCount:
      'custom_SaleOutboundChecking_out_packing_scan_error_count',
    /**
     * 全流程错误数量
     */
    ErrorCount: 'custom_SaleOutboundChecking_error_count',

    getPointList: function (): any[] {
      return [
        /**
         * 监听错误数量开始
         */
        // 监听 SKU 扫描请求报错
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/(search_checking_possible_sku_list|scan_checking_sku)(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, _: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode !== 0) {
              this.status.skuScanErrorCount += 1;
            }
          },
        },
        // 监听内包材扫描请求报错
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/scan_inner_packaging(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, _: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode !== 0) {
              this.status.innerPackingScanErrorCount += 1;
            }
          },
        },
        // 监听外包材扫描请求报错
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/scan_packaging_id(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, _: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode !== 0) {
              this.status.outPackingScanErrorCount += 1;
            }
          },
        },
        // 监听所有的请求报错
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\//,
            state: RequestState.After,
          },
          handler: (trackData: any, _: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode !== 0) {
              this.status.errorCount += 1;
            }
          },
        },
        /**
         * 监听错误数量结束
         */

        /**
         * 监听流程耗时开始
         */
        /**
         * 绑定 station ID，主要获取是否需要移除重量输入
         * 如果 remove_weight_input 为 true，则说明需要移除重量输入
         */
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/bind_station(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const {
                data: { remove_weight_input },
              } = response;
              this.taskInfo.remove_weight_input = remove_weight_input;
            }
          },
        },
        // Checking任务开始：点击 Picking ID
        {
          type: CustomActionType.Focus,
          rule: {
            selectors: '#searchKey',
          },
          handler: () => {
            this.collector = DataCollectorInstance.createTransactionCollector();
            // 开始全流程耗时
            this.collector.startTrack({
              name: this.ALLName,
            });
            // 重置错误数量
            this.status.errorCount = 0;
            this.status.skuScanErrorCount = 0;
            this.status.innerPackingScanErrorCount = 0;
            this.status.outPackingScanErrorCount = 0;

            this.taskInfo.isAllSKUScanStart = false;
          },
        },

        /**
         * 获取当前任务的状态，主要是是否需要扫描内外包材
         * need_scan_inner_packaging & need_scan_outer_packaging
         * 如果不需要获取内外包材，则说明扫描 SKU 的全流程是：由结束 Picking ID 扫描到输入重量结束
         */
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/search_checking_task_info(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const {
                data: {
                  need_scan_inner_packaging,
                  need_scan_outer_packaging,
                  wave_type,
                },
              } = response;
              this.taskInfo.need_scan_inner_packaging =
                need_scan_inner_packaging;
              this.taskInfo.need_scan_outer_packaging =
                need_scan_outer_packaging;
              this.taskInfo.wave_type = wave_type;
            }
          },
        },

        // 扫描 SKU 开始
        {
          type: CustomActionType.Focus,
          rule: {
            selectors: '#skuScanning',
          },
          handler: () => {
            // 记录单个扫描 SKU 开始时间
            this.collector.startTrack({
              name: this.ScanSKU,
            });

            if (!this.taskInfo.isAllSKUScanStart) {
              // 记录扫描 SKU 全流程开始时间
              this.taskInfo.isAllSKUScanStart = true;
              // 开始扫描 SKU 全流程耗时
              this.collector.startTrack({
                name: this.ScanSKUAll,
              });
            }
          },
        },
        // 扫描 SKU 结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/scan_checking_sku(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              // 结束单个扫描 SKU 耗时
              const [result] = this.collector.endTrackByName({
                name: this.ScanSKU,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: {
                  data: { wave_type_n: this.taskInfo.wave_type },
                  ...result,
                  type: 'experienceTime',
                },
              });

              // 扫描单个 SKU 结束后，判断是否还需要继续扫描，需要的话从这里算 SKU 开始
              const {
                data: { checked_qty, total_qty },
              } = response;
              if (checked_qty < total_qty) {
                this.collector.startTrack({
                  name: this.ScanSKU,
                });
              }
            }
          },
        },

        // 扫描 Inner Packing ID 开始
        {
          type: CustomActionType.Focus,
          rule: {
            selectors: '#inputInnerPackagingID',
          },
          handler: (trackData: any, sendDataFn: any) => {
            // 第一次聚焦内包材输入框时，记录单次内包材开始
            this.collector.startTrack({
              name: this.ScanInnerPacking,
            });

            if (this.taskInfo.need_scan_inner_packaging) {
              // 结束扫描 SKU 全流程耗时
              const [result] = this.collector.endTrackByName({
                name: this.ScanSKUAll,
              });
              sendDataFn({
                data: { wave_type_n: this.taskInfo.wave_type },
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });

              // 记录扫描全程内包材开始时间
              this.collector.startTrack({
                name: this.ScanInnerPackingAll,
              });
            }
          },
        },
        // 扫描 Inner Packing ID 结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/scan_inner_packaging(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              // 结束单次内包材扫描耗时
              const [result] = this.collector.endTrackByName({
                name: this.ScanInnerPacking,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });

              // 扫描单个内包材结束后，判断是否还需要继续扫描，需要的话从这里算内包材开始
              const {
                data: { is_all_done },
              } = response;
              if (!is_all_done) {
                this.collector.startTrack({
                  name: this.ScanInnerPacking,
                });
              }
            }
          },
        },

        // 扫描外包材开始
        {
          type: CustomActionType.Focus,
          rule: {
            selectors: '#inputOuterPackagingID',
          },
          handler: (trackData: any, sendDataFn: any) => {
            // 第一次聚焦外包材输入框时，记录外包材开始
            this.collector.startTrack({
              name: this.ScanOutPacking,
            });

            if (
              !this.taskInfo.need_scan_inner_packaging &&
              this.taskInfo.need_scan_outer_packaging
            ) {
              // 结束扫描 SKU 全流程耗时
              const [result] = this.collector.endTrackByName({
                name: this.ScanSKUAll,
              });
              sendDataFn({
                data: { wave_type_n: this.taskInfo.wave_type },
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }

            if (this.taskInfo.need_scan_inner_packaging) {
              // 结束扫描内包材全流程耗时
              const [result] = this.collector.endTrackByName({
                name: this.ScanInnerPackingAll,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },
        // 扫描外包材结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/scan_packaging_id(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              // 结束扫描外包材耗时
              const [result] = this.collector.endTrackByName({
                name: this.ScanOutPacking,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },

        // 输入重量开始
        {
          type: CustomActionType.Focus,
          rule: {
            selectors: '#inputWeight',
          },
          handler: (trackData: any, sendDataFn: any) => {
            this.collector.startTrack({
              name: this.ScanWeight,
            });

            // 如果不需要扫描内外包材，则说明扫描 SKU 的全流程是：由结束 Picking ID 扫描到输入重量结束
            if (
              !this.taskInfo.need_scan_inner_packaging &&
              !this.taskInfo.need_scan_outer_packaging &&
              !this.taskInfo.remove_weight_input
            ) {
              // 结束扫描 SKU 全流程耗时
              const [result] = this.collector.endTrackByName({
                name: this.ScanSKUAll,
              });
              sendDataFn({
                data: { wave_type_n: this.taskInfo.wave_type },
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }

            if (
              this.taskInfo.need_scan_inner_packaging &&
              !this.taskInfo.need_scan_outer_packaging
            ) {
              // 结束扫描内包材全流程耗时
              const [result] = this.collector.endTrackByName({
                name: this.ScanInnerPackingAll,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },
        // 输入重量结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/add_weight(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const [result] = this.collector.endTrackByName({
                name: this.ScanWeight,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },

        // Checking结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/complete_order(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              // 结束扫描 SKU 全流程耗时，不需要扫描内外包材，也不需要输入重量的情况
              if (
                !this.taskInfo.need_scan_inner_packaging &&
                !this.taskInfo.need_scan_outer_packaging &&
                this.taskInfo.remove_weight_input
              ) {
                // 结束扫描 SKU 全流程耗时
                const [result] = this.collector.endTrackByName({
                  name: this.ScanSKUAll,
                });
                sendDataFn({
                  data: { wave_type_n: this.taskInfo.wave_type },
                  pointId: result.name,
                  customData: { ...result, type: 'experienceTime' },
                });
              }

              // 结束扫描内包材全流程耗时
              if (
                this.taskInfo.need_scan_inner_packaging &&
                !this.taskInfo.need_scan_outer_packaging &&
                this.taskInfo.remove_weight_input
              ) {
                const [result] = this.collector.endTrackByName({
                  name: this.ScanInnerPackingAll,
                });
                sendDataFn({
                  pointId: result.name,
                  customData: { ...result, type: 'experienceTime' },
                });
              }

              // 全流程耗时上报
              const [allResult] = this.collector.endTrackByName({
                name: this.ALLName,
                isSuccess: true,
                endAll: true,
              });
              sendDataFn({
                pointId: allResult.name,
                customData: { ...allResult, type: 'experienceTime' },
              });

              // 上报全流程错误数量
              sendDataFn({
                pointId: this.ErrorCount,
                customData: {
                  errorCount_n: this.status.errorCount,
                  type: 'experienceCount',
                },
              });
              // 上报 SKU 扫描阶段错误数量
              sendDataFn({
                pointId: this.SKUScanErrorCount,
                customData: {
                  data: { wave_type_n: this.taskInfo.wave_type },
                  errorCount_n: this.status.skuScanErrorCount,
                  type: 'experienceCount',
                },
              });
              // 上报内包材扫描阶段错误数量
              sendDataFn({
                pointId: this.InnerPackingScanErrorCount,
                customData: {
                  errorCount_n: this.status.innerPackingScanErrorCount,
                  type: 'experienceCount',
                },
              });
              // 上报外包材扫描阶段错误数量
              sendDataFn({
                pointId: this.OutPackingScanErrorCount,
                customData: {
                  errorCount_n: this.status.outPackingScanErrorCount,
                  type: 'experienceCount',
                },
              });
              DataCollectorInstance.removeTransactionCollector({
                traceId: this.collector.traceId,
              });
            }
          },
        },
        /**
         * 监听流程耗时结束
         */
      ];
    },
  },
];
