<template>
  <s-popover
    v-if="visible"
    trigger="hover"
    ref="ssPopover"
    :width="410"
    @show="handleShowPopover"
  >
    <div slot="default">
      <h3>设置当前子应用的 local host</h3>
      <br />
      <s-form
        ref="ruleForm"
        size="small"
        :model="formModel"
        class="demo-ruleForm"
        :hide-required-asterisk="false"
        :inline="false"
        label-width="150px"
        :scroll-to-error-item="true"
      >
        <s-form-item
          v-for="item in forms"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :inline="true"
          label-class="no-vertical-padding-label"
          item-class="align-item-class"
        >
          <s-input
            v-model="formModel[item.prop]"
            width="200px"
            placeholder="例子：http://localhost:3000"
          />
        </s-form-item>
      </s-form>
      <div :style="{ 'text-align': 'right' }">
        <s-button @click="hide()"> Hide </s-button>
        <s-button @click="reset()" :style="{ marginLeft: '10px' }">
          Reset
        </s-button>
        <s-button
          type="primary"
          :style="{ marginLeft: '10px' }"
          @click="submit()"
        >
          Submit&Refresh
        </s-button>
      </div>
    </div>
    <span
      v-if="visible"
      :class="['remote-host-setting', { 'setting-active': active }]"
      slot="reference"
    >
      <SIconChevronLeft width="16" type="info"></SIconChevronLeft>
    </span>
  </s-popover>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { readSetting, removeSetting, writeSetting } from './utils';
import { getEnv } from '@/utils/getEnv';

/**
 * 仅供 none-live 环境调用，用于线上环境调试本地子应用代码的工具
 */

const SubDomains = ['workforce'];

@Component
export default class extends Vue {
  // @ts-ignore
  visible = getEnv() === 'test' || getEnv() === 'development';
  active = false;
  forms: any[] = SubDomains.map((k) => ({ prop: k, label: k }));

  formModel = SubDomains.reduce((map, key) => ({ ...map, [key]: '' }), {});

  created() {
    this.init();
  }

  init() {
    SubDomains.forEach((key) => {
      const value = readSetting(key);
      if (!value) {
        this.formModel[key] = '';
        return;
      }
      this.formModel[key] = value;
      this.active = true;
    });
  }

  handleShowPopover() {
    this.init();
  }

  hide() {
    this.visible = false;
  }

  reset() {
    // @ts-ignore
    SubDomains.forEach((key) => {
      this.formModel[key] = '';
    });
    // @ts-ignore
    this.$message.success('已重置设置 请提交并刷新');
  }

  submit() {
    // @ts-ignore
    this.$refs.ruleForm.validate((valid) => {
      if (!valid) return;
      SubDomains.forEach((key) => {
        const value = this.formModel[key];
        if (!value) return removeSetting(key);
        writeSetting(key, value);
      });
      // @ts-ignore
      this.$message.success('即将刷新页面应用设置');
      window.location.reload();
    });
  }
}
</script>

<style lang="scss" scoped>
.remote-host-setting {
  position: fixed;
  bottom: 16px;
  right: -1px;
  z-index: 10001;
  cursor: pointer;
  border: 1px solid #dcdfe6;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;

  &.setting-active {
    path {
      fill: #ee4d2d;
    }
  }
}
</style>
