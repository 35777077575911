import { AbstractPlugin } from '@scfe-common/chain-tracker';
import { CustomActionType } from './types';

// 继承AbstractPlugin
class FocusPlugin extends AbstractPlugin {
  name = CustomActionType.Focus;

  constructor() {
    super();
    this.start();
  }

  handle = (e: MouseEvent) => {
    this.dispatch({
      type: this.name,
      params: {
        targetDom: e.target as Element,
      },
    });
  };

  // 事件收集
  start = () => {
    document.body.addEventListener('focusin', this.handle);
  };

  stop = () => {
    document.body.removeEventListener('focusin', this.handle);
  };

  checkFn = ({ params: { targetDom } }: any, { selectors }: any) => {
    return Boolean(targetDom.closest(selectors));
  };
}

export default FocusPlugin;
