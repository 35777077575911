import { SoundStatus } from '@/views/system-config/notification-center/constant';
import store from '@/store/index';
import { safeGet } from '@/utils/safeGet';
import SuccessSoundPath from '@/assets/audio/success-notification-sound.mp3';
import ScanErrorAlertPath from '@/assets/audio/scan_error_alert.mp3';
import { errorReport } from '@/utils/tools';

type NotificationSoundConfigItem = {
  id: number;
  node_name: string;
  sound_type: number;
};

/**
 * 操作声音提示配置处理函数
 * @param operationNodeName 节点名称
 */
export function notificationSoundProcess(operationNodeName: string) {
  return new Promise((resolve) => {
    const config: NotificationSoundConfigItem[] =
      safeGet(store.state.notification, 'notificationSoundConfig') || [];
    if (
      config
        .filter((item) => item.sound_type === SoundStatus.Open)
        .map((item) => item.node_name)
        .includes(operationNodeName)
    ) {
      const audio = new Audio(SuccessSoundPath);
      audio.play();
    }
    resolve(0);
  });
}

export function errorNotificationSound() {
  return new Promise((resolve) => {
    const audio = new Audio(ScanErrorAlertPath);
    audio.play();
    resolve(0);
  });
}

// 提前初始化声音列表
export function initVoice() {
  window.speechSynthesis.getVoices();
}

interface VoiceConfig {
  text: string;
  lang: string;
  vol?: number;
  rate?: number;
}

// 文字合成语音
export function textToVoice(config: VoiceConfig): void {
  window.speechSynthesis.cancel();
  const instance = new window.SpeechSynthesisUtterance();
  instance.text = config.text; // 文字内容
  instance.lang = config.lang || 'en-US'; // 语言
  const voices = window.speechSynthesis.getVoices();
  const voicesArr = voices.filter(function (voice) {
    return voice.lang == config.lang;
  });
  if (voicesArr.length == 0) {
    instance.lang = 'en-US';
    errorReport(`没有可用的${config.lang}语音!`);
  } else {
    instance.voice = voicesArr[0];
  }
  instance.volume = config.vol || 1; // 音量
  instance.rate = config.rate || 1; // 语速
  instance.pitch = 1; // 音高
  window.speechSynthesis.speak(instance); // 播放
}
