<template>
  <div :class="['sidebar', { 'collapse-sidebar': collapse }]">
    <section class="wms-title">
      <div class="title-logo">
        <div v-if="collapse" class="collapse-title">
          <svg-icon class="shopee-icon" icon-class="shopee-mobile" />
          <s-tag
            size="small"
            theme="special"
            :type="getCapitilizeEnv() === 'LIVE' ? 'info' : 'default'"
          >
            {{ getCapitilizeEnv() }}
          </s-tag>
        </div>
        <div v-else class="expand-title">
          <svg-icon class="wms-icon" icon-class="shopee-wms" />
          <s-tag
            size="small"
            theme="special"
            :type="getCapitilizeEnv() === 'LIVE' ? 'info' : 'default'"
          >
            {{ getCapitilizeEnv() }}
          </s-tag>
        </div>
      </div>
      <s-select
        v-if="!collapse"
        class="whs-select"
        v-model="whs"
        :automatic-dropdown="false"
        :filterable="true"
        @change="localeChange"
        dropdown-wrapper-class="whs-select-dropdown"
      >
        <s-option
          class="whs-options"
          v-for="whs in whsList"
          :key="whs.whs_id"
          :value="whs.whs_id"
          :label="whs.label"
        />
      </s-select>
      <s-menu
        class="whs-select-collapse"
        v-else
        :default-active-name="whsName"
        :collapse="collapse"
        :no-indent="true"
        text-color="rgba(255,255,255,0.6)"
        sub-menu-text-color="rgb(255,255,255)"
        active-text-color="rgb(255,255,255)"
        background-color="#1F262F"
        hover-sub-menu-color="#303844"
        active-sub-menu-color="#0E1723"
      >
        <s-submenu name="whs-list">
          <template slot="title">
            <img
              class="collapse-warehouse-icon"
              src="../../../../icon/warehouse.svg"
            />
          </template>
          <s-menu-item
            v-for="whs in whsList"
            :key="whs.whs_id"
            :name="whs.label"
            @click.native="localeChange(whs.whs_id)"
          >
            <span class="whs-name">{{ whs.label }}</span>
          </s-menu-item>
        </s-submenu>
      </s-menu>
      <FuzzySearch :menuList="menuList" v-if="!collapse" />
      <div v-else class="collapse-search-menu-wrap">
        <img class="collapse-search-icon" src="../../../../icon/search.svg" />
        <div class="search-menu">
          <FuzzySearch isCollapse="true" :menuList="menuList" />
        </div>
      </div>
    </section>

    <section class="menu">
      <s-menu
        :default-active-name="activeName"
        :default-opened-names="defaultOpenedNames"
        :collapse="collapse"
        :no-indent="true"
        :accordion="true"
        text-color="rgba(255,255,255,0.6)"
        sub-menu-text-color="rgb(255,255,255)"
        active-text-color="rgb(255,255,255)"
        background-color="#1F262F"
        hover-sub-menu-color="#303844"
        active-sub-menu-color="#0E1723"
        :router="true"
        ref="sMenu"
      >
        <Menu :menuList="menuList" />
      </s-menu>
    </section>
    <div class="footer" @click="collapse = !collapse">
      <img
        v-if="collapse"
        class="collapse-left-icon"
        src="../../../../icon/collapse-left.svg"
        alt=""
      />
      <img
        v-else
        class="collapse-right-icon"
        src="../../../../icon/collapse-right.svg"
        alt=""
      />
      <div :class="collapse ? 'collapse-left' : 'collapse-right'" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator';
import routesConfig from '../../../../router/config';
import regionOverviewRoutesConfig from '../../../../router/region-overview-config';
import Menu from './menu.vue';
import { State } from 'vuex-class';
import { logOut, setUserSetting } from '@/api/basic/user';
import { getCookie } from '@/utils/cookie';
import { getEnv, getPFB, isXxEnv } from '@/utils/getEnv';
import FuzzySearch from './fuzzySearch.vue';
import { convertReactRoutesToVue } from '@/utils/bridge';
import { reactAppRoutes } from '@/react-app';

const FORBIDDEN_IP_CODE = -100403;
import {
  filterByPermission,
  asyncConfigQueue,
  changeMenuList,
} from '../dynamicTitle';
import { dynamicMap } from '../config';
import { searchMenuRouterName } from '@/utils/menu';
import { getCurrentRegionName, IS_SG, IS_VN, IS_TW } from '@/utils/tools';

let hasPostMessageHandler = false;

interface ItemToSubmenu {
  [propName: string]: Array<string>;
}
// interface WhsListItem {
//   whs_id: string;
//   country: string;
// }

@Component({
  components: {
    Menu,
    FuzzySearch,
  },
})
export default class SideBar extends Vue {
  @State user: any;
  @State permission: any;

  collapse = false;
  whs = 'IDG';
  whsList: any[] = [];
  activeName = '';
  defaultOpenedNames: Array<string> = [];
  menuList: object[] = [];
  routesConfig = routesConfig;
  regionOverviewRoutesConfig = regionOverviewRoutesConfig;

  // 履约相关菜单 name list
  fulfilChainRouterList = [
    'ruleCenter.fulfillmentchain',
    'ruleCenter.inventoryroute',
    'transfer',
    'reportCenter.destinationcalculation',
    'ruleCenter.fulfillmentchainturnoverdaysrule',
    'ruleCenter.fulfillmentchaindailysalesrule',
    'wes',
  ];
  // 履约相关菜单 name list
  // replenishmentRouterList = [
  //   'inventoryManagement.replenishReverse',
  //   'inventoryManagement.replenishReverse.demandPool',
  // ];

  ZoneColorRouter = ['ruleCenter.pickingRule.zonecolorconfig'];
  getCapitilizeEnv() {
    const pfbTag = getPFB();
    return pfbTag || getEnv().toUpperCase();
  }

  get whsName() {
    const whsInfo = this.whsList.find(
      (item: any) => item.whs_id === this.whs
    ) || { country: '', whs_id: '', label: '' };
    return whsInfo.label;
  }

  @Watch('$route', { immediate: true })
  onAfterChanged(to: any) {
    if (to.meta && to.meta.menuHide && !to?.name?.startsWith('PC.')) {
      return;
    }
    if (to.name) {
      this.activeName = to.name.startsWith('PC.')
        ? searchMenuRouterName(to.name)
        : to.name;
      if (!this.activeName) return;
      this.defaultOpenedNames = this.getNewDefaultOpenedNames(this.activeName);
      if ((this as any).$refs.sMenu) {
        this.$nextTick(() => {
          // console.log((this as any).$refs.sMenu.updateOpenedSubmenus);
          (this as any).$refs.sMenu.updateOpenedSubmenus();
          (this as any).$refs.sMenu.updateActiveName();
        });
      }
    }
  }

  // 以 regionOverview 开头的 path
  get isRegionDashboardPage() {
    const regionOverviewReg = /^\/regionOverview/;
    const match = regionOverviewReg.test(String(this.$route?.path));
    return match;
  }

  getNewDefaultOpenedNames(name: string): Array<string> {
    const nameStack = name.split('.');
    const newOPenedNames = nameStack.reduce(
      (acc: Array<string>, cur: string, curIndex: number) => {
        // 最多三层
        if (curIndex > 2) {
          return acc;
        }
        if (curIndex === 0) {
          return acc.concat(cur);
        }
        return acc.concat(`${acc[curIndex - 1]}.${cur}`);
      },
      []
    );
    return newOPenedNames;
  }

  openedNames() {
    const itemToSubmenu = this.getSubMenuName();
    if (!itemToSubmenu) {
      this.defaultOpenedNames = [];
    }
    this.defaultOpenedNames = itemToSubmenu[this.activeName] || [];
  }

  getSubMenuName() {
    // 递归获取submenu和menuItem对应的父级name
    const itemToSubmenu: ItemToSubmenu = {};
    const getChildren = (item: any, parentName?: string) => {
      itemToSubmenu[item.name] = parentName
        ? [...itemToSubmenu[parentName], parentName]
        : [];
      // item 为 submenu 的情况
      if (item.children && item.children.length) {
        item.children.forEach((i: any) => {
          getChildren(i, item.name);
        });
      }
    };
    this.menuList.forEach((item: any) => {
      getChildren(item);
    });
    return itemToSubmenu;
  }

  initMenu() {
    // 递归处理children，去除掉meta.menuHide=true的节点
    const getChildren = (item: any) => {
      const children: object[] = [];
      if (item.children && item.children.length > 0) {
        item.children.forEach((i: any) => {
          if (
            (i.meta && i.meta.menuHide) ||
            !this.permission.permissionMenu.includes(i.name)
          ) {
            return;
          }

          // 根据开关控制履约菜单展示（二级菜单）
          if (!this.$store.state.system.enableFulfilChain) {
            if (this.fulfilChainRouterList.includes(i.name)) {
              return;
            }
          }

          // 根据开关控制补货菜单展示
          // if (!this.$store.state.system.enableDynamicReplenishment) {
          //   if (this.replenishmentRouterList.includes(i.name)) {
          //     return;
          //   }
          // }

          // 根据开关控制Zone Color菜单展示
          if (!this.$store.state.system.showZoneColor) {
            if (this.ZoneColorRouter.includes(i.name)) {
              return;
            }
          }

          // 根据开关控制 dashboard configuration 目录展示
          if (!this.$store.state.system.showDashboardConfiguration) {
            if (i.name === 'dashboard.configuration') {
              return;
            }
          }

          // 根据开关控制 dashboard configuration 目录展示
          if (!this.$store.state.system.inboundParcelCountingConfig) {
            if (i.name === 'inbound.parcelcounting') {
              return;
            }
          }

          const _children = getChildren(i);

          if (_children.length > 0) {
            i.children = _children;
          } else {
            i.children && delete i.children;
          }
          children.push(i);
          // if (i.name == 'salesOutbound.task') {
          //   console.log('ooooo', i, _children);
          // }
        });
      }
      return children;
    };

    this.menuList = [];
    let routerConfig: any = [];
    // 以 regionOverview 开头的 path 走另一套 router
    if (this.isRegionDashboardPage) {
      routerConfig = this.regionOverviewRoutesConfig;
    } else {
      routerConfig = this.routesConfig;
    }
    routerConfig.forEach((item: any) => {
      // dev环境添加test & React 目录
      if (['Test'].includes(item.name) && getEnv() === 'dev') {
        this.menuList.push(item);
        return;
      }

      // 根据开关控制履约菜单展示（一级菜单）
      if (!this.$store.state.system.enableFulfilChain) {
        if (this.fulfilChainRouterList.includes(item.name)) {
          return;
        }
      }

      // 控制 cctv 菜单展示，非 SG 不展示 cctv 菜单
      if (!IS_SG && !IS_TW && !IS_VN) {
        if ('cctvmanagement' === item.name) {
          return;
        }
      }

      // if (['ReactDemo'].includes(item.name)) {
      //   this.menuList.push(item);
      //   return;
      // }
      if (
        (item.meta && item.meta.menuHide) ||
        !this.permission.permissionMenu.includes(item.name)
      ) {
        return;
      }

      if (
        !this.$store.state.system.enableFulfilChain &&
        this.fulfilChainRouterList.includes(item.name)
      ) {
        return;
      }
      const _children = getChildren(item);
      if (_children.length > 0) {
        item.children = _children;
      }
      this.menuList.push(item);
    });
  }
  localeChange(locale: string) {
    // 如果选择了 admin portal新开一个页面到admin portal， 原页面还是之前的仓库
    if (locale === 'Admin Portal') {
      if (getEnv() === 'dev') {
        window.open('https://ops.ssc.test.shopeemobile.com/wms/index.html');
      } else if (getEnv() === 'live') {
        window.open('https://ops.ssc.shopeemobile.com/wms/index.html');
      } else {
        window.open(
          `https://ops.ssc.${getEnv()}.shopeemobile.com/wms/index.html`
        );
      }
      this.whs = getCookie('setting_whs_v2') || this.whs;
      return;
    }

    if (locale === 'Region Dashboard') {
      this.whs = getCookie('setting_whs_v2') || this.whs;
      const page = this.$router.resolve({
        name: 'dashboard.regionoverview',
      });
      window.open(page.href, '_blank');
      return;
    }
    setUserSetting('whs', locale)
      .then(async () => {
        await localStorage.setItem('country', this.whsName);
        if (this.isRegionDashboardPage) {
          this.whs = 'Region Dashboard';
          const page = this.$router.resolve({
            name: 'home',
          });
          window.open(page.href, '_blank');
        } else {
          window.location.reload();
        }
      })
      .catch(async (e) => {
        if (e.retcode && e.retcode === FORBIDDEN_IP_CODE) {
          await logOut();
          this.$store.commit('clearUserInfo');
          this.$router.push({ name: '403' });
        }
      });
  }
  created() {
    this.initMenu();
    this.openedNames();
    const list = this.user.userInfo.whs_id_country || [];
    this.whsList = list.map((whs: any) => {
      return { ...whs, label: whs.country + ' - ' + whs.whs_id };
    });
    // Regin Overview 菜单
    if (this.$hasPermission('PC.Dashboard.RegionOverview')) {
      this.whsList.push({
        whs_id: 'Region Dashboard',
        country: '',
        label: `${getCurrentRegionName()} - Region Dashboard`,
      });
    }

    // 目前后端没有做好权限控制，所以admin 入口先加上，让 BPM 验证，后面再做权限
    if (!isXxEnv()) {
      this.whsList.push({
        whs_id: 'Admin Portal',
        country: '',
        label: '(Admin Portal)',
      });
    }
    // Regin Overview 的 route 设置 whs
    if (this.isRegionDashboardPage) {
      this.whs = 'Region Dashboard';
    } else {
      this.whs = getCookie('setting_whs_v2') || this.whs;
    }
    this.$nextTick(() => {
      this.patchMenuTitle();
    });
  }

  mounted() {
    // 确保只有一个 handle避免反复触发
    if (!hasPostMessageHandler) {
      window.addEventListener('message', this.handleMessage);
      hasPostMessageHandler = true;
    }
  }
  handleMessage(event: any) {
    if (event?.data?.hasOwnProperty('changeHub')) {
      const hubOptions = event.data.changeHub; // { whs_id: xx }
      if (hubOptions) {
        const whsInfo = this.whsList.find(
          (item: any) => item.whs_id === hubOptions.whs_id
        );
        whsInfo && this.localeChange(hubOptions.whs_id);
      }
    }
  }

  async patchMenuTitle() {
    const task = filterByPermission(dynamicMap, this.permission.permissionMenu);
    const result = await asyncConfigQueue(task);
    const newMenuList = changeMenuList(this.menuList, result);
    if (newMenuList.length) {
      this.menuList = newMenuList;
    }
  }
}
</script>

<style scoped lang="scss">
.wms-title {
  ::v-deep .ssc-tag.ssc-tag-theme-special .ssc-tag-content {
    color: #ffffff;
  }
}

.collapse-search-icon {
  margin-left: 19px;
}

.collapse-search-menu-wrap {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  cursor: pointer;

  .search-menu {
    position: absolute;
    top: -8px;
    left: 43px;
    display: none;
    border-radius: 4px;
    filter: drop-shadow(0 6px 16px rgba(0, 0, 0, 0.12));

    ::v-deep .fuzzy-search {
      background: #ffffff;

      input {
        color: #000000 !important;
      }
    }
  }

  &:hover {
    .search-menu {
      display: block;
    }
  }
}

.sidebar {
  z-index: 9;
  display: flex;
  width: 240px;
  height: 100vh;
  font-size: 14px;
  background: #222933;
  flex-direction: column;
}

.collapse-sidebar {
  width: 56px;
}

.title-logo {
  // height: 56px;
  background: #222933;

  .collapse-title {
    display: flex;
    align-items: center;
    margin: 7px;
    flex-direction: column;

    .ssc-tag {
      margin: 2px 0 0;
      height: 14px;
      font-weight: 700;
    }

    .shopee-icon {
      width: 22px;
      height: 25px;
    }
  }

  .expand-title {
    display: flex;
    align-items: center;
    margin: 14px 31px;

    .ssc-tag {
      margin: 0 0 0 4px;
      height: 14px;
      font-weight: 700;
    }

    .wms-icon {
      width: 136px;
      height: 28px;
    }
  }
}

.whs-select {
  display: flex;
  margin: 0 32px;
  // margin: 0 auto 16px;
  // border: 1px solid #e0e0e0;
  // border-radius: 16px;
  border: none;
  width: 176px;
  height: 40px;
  font-size: 14px;
  color: #ffffff;
  background: none;
  line-height: 32px;
  letter-spacing: 0;
}

.whs-name {
  overflow: hidden;
  width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whs-select ::v-deep {
  .ssc-select-reference-wrapper {
    margin-left: -16px;
    padding-left: 32px;
    height: 40px;
    cursor: pointer;
  }

  .ssc-select-single-value-wrapper {
    color: #ffffff;
  }

  .ssc-select-single-value-wrapper input {
    color: #ffffff;
  }

  .ssc-select-arrow .ssc-select-arrow-icon {
    stroke: #222933;
    color: #ffffff;
  }

  .ssc-select-content::before {
    color: #ffffff;
  }
}

.menu {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;

  ::v-deep .ssc-menu-no-indent .ssc-menu-item:hover {
    color: #ffffff !important;
    background: #303844 !important;
  }

  ::v-deep .ssc-menu-no-indent .ssc-menu-submenu-title :hover {
    color: #ffffff !important;
  }

  ::v-deep .ssc-menu-submenu-title-icon {
    color: #ffffff;
  }
}

.menu::-webkit-scrollbar {
  width: 4px;
}

.menu::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
}

.footer {
  height: 40px;
  text-align: right;
  cursor: pointer;
}

.collapse-right-icon {
  margin: 8px;
}

.collapse-left-icon {
  margin: 8px 16px;
}

.whs-select-collapse {
  width: 56px;

  .collapse-warehouse-icon {
    width: 16px;
    height: 16px;
  }

  ::v-deep .ssc-menu-drop-list {
    overflow-y: auto;
    max-height: 320px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.5);
    }

    li:first-child {
      display: none;
    }
  }
}
</style>
<style lang="scss">
.whs-select-dropdown .ssc-options-header {
  border: none;
}

.whs-select-dropdown.ssc-option-wrapper {
  width: 208px;

  .ssc-options {
    .ssc-option-list-wrapper {
      width: 208px;
    }
  }
}
</style>
