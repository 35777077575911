export default [
  {
    path: 'actList',
    name: 'laborManagement.actList',
    meta: {
      title: 'Activity List',
    },
    redirect: '/wms-labormanagement/actList',
    // component: () => import('@/views/labor-management/activity-list/index.vue'),
  },
];
