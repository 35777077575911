// const reg = /^(scm|microBase|scsAdminInventory).*/;

export const readSetting = (key) => window.sessionStorage.getItem(key);
export const writeSetting = (key, value) =>
  window.sessionStorage.setItem(key, value);
export const removeSetting = (key) => window.sessionStorage.removeItem(key);

export const readSettingFromSession = () => {
  const allKeys = Object.keys(window.sessionStorage);
  allKeys.forEach((key) => {
    // if (!reg.test(key)) return;
    const value = window.sessionStorage.getItem(key) || '';
    window.localStorage.setItem(key, value);
  });
};

export const writeSettingToSession = () => {
  const allKeys = Object.keys(window.localStorage);
  allKeys.forEach((key) => {
    // if (!reg.test(key)) return;
    const value = window.localStorage.getItem(key) || '';
    window.sessionStorage.setItem(key, value);
    window.localStorage.removeItem(key);
  });
};
