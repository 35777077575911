import store from '@/store';
import { errorReport } from '@/utils/tools';

export const hasMenuPermission = (to: any) => {
  // 本地开发环境在根目录下 .env.development.local 文件中
  // 配置VITE_VUE_APP_SKIP_PERMISSION = 1时，放开所有权限限制
  // console.log(import.meta.env, process.env);
  if (
    process.env.VUE_APP_SKIP_PERMISSION === '1' ||
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    import.meta?.env?.VITE_VUE_APP_SKIP_PERMISSION === '1'
  ) {
    return true;
  }
  // TODO: temp handle for react-demo test
  if (to.path.includes('react-demo')) {
    return true;
  }
  if (to.path.includes('login_callback')) {
    return true;
  }
  if (
    to.path.includes('cctv-management') ||
    to.path.includes('hubLinehaulTrips') ||
    to.path.includes('wms-labormanagement') ||
    to.path.includes('wms-dashboard') ||
    to.path.includes('workforce')
  ) {
    return true;
  }
  return (
    to.path === '/' ||
    store.state.permission.permissionMenu.includes(to.name) ||
    store.state.permission.addRouters.includes(to.name)
  );
};

export const hasPermission = (value: string) => {
  if (
    process.env.VUE_APP_SKIP_PERMISSION === '1' ||
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    import.meta?.env?.VITE_VUE_APP_SKIP_PERMISSION === '1'
  ) {
    return true;
  }
  if (store.state.permission.userPermission.includes(value)) {
    return true;
  }
  return false;
};

export const permissionLog = (
  permissionMenu: string[],
  hideRouters: string[],
  routeName: string
) => {
  errorReport('当前跳转页面路由 name 为' + routeName);
  // console.log('当前跳转页面路由 name 为', routeName);
  console.log(
    '当前跳转页面是否应该显示在导航栏',
    routeName,
    permissionMenu.includes(routeName)
  );
  console.log(
    '当前跳转页面路由是否配置了 menuHide: true 属性',
    routeName,
    hideRouters.includes(routeName)
  );
  console.log(
    '导航栏入口的页面请联系后端配置权限，其他页面请联系前端配置 menuHide'
  );
};
