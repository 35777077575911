export default {
  path: '/wk',
  name: 'workforce',
  meta: {
    title: 'Workforce',
    icon: 'Labor Management',
  },
  redirect: '/workforce/roster-planning',
  children: [
    {
      path: 'roster-planning',
      name: 'workforce.rosterplanning',
      meta: {
        title: 'Roster Planning',
      },
      redirect: '/workforce/roster-planning/slot-list',
      children: [
        {
          path: 'slot-list',
          name: 'workforce.rosterplanning.timeslot',
          meta: {
            title: 'TimeSlot Configuration',
          },
          redirect: '/workforce/roster-planning/time-slot',
        },
        {
          path: 'shift-list',
          name: 'workforce.rosterplanning.shift',
          meta: {
            title: 'Shift Management',
          },
          redirect: '/workforce/roster-planning/shift/list',
        },
        {
          path: 'event-list',
          name: 'workforce.rosterplanning.event',
          meta: {
            title: 'Event Roster Planning',
          },
          redirect: '/workforce/roster-planning/event',
        },
      ],
    },
    {
      path: 'attendance-management',
      name: 'workforce.attendancemanagement',
      meta: {
        title: 'Attendance Management',
      },
      redirect: '/workforce/attendance-management/attendance-taking',
      children: [
        {
          path: 'attendanceTaking',
          redirect: '/workforce/attendance-management/attendance-taking',
          name: 'workforce.attendancemanagement.attendancetaking',
          meta: {
            title: 'Attendance Taking',
          },
        },
        {
          path: 'activityAttendance',
          redirect: '/workforce/attendance-management/activity-attendance',
          name: 'workforce.attendancemanagement.activityattendancetaking',
          meta: {
            title: 'Activity Attendance',
          },
        },
        {
          path: 'attendanceRecord',
          redirect: '/workforce/attendance-management/attendance-record',
          name: 'workforce.attendancemanagement.attendancerecord',
          meta: {
            title: 'Attendance Record',
          },
        },
        {
          path: 'activityAttendanceLog',
          redirect: '/workforce/attendance-management/activity-attendance-log',
          name: 'workforce.attendancemanagement.activityattendancetakinglog',
          meta: {
            title: 'Activity Record',
          },
        },
      ],
    },
    {
      path: 'activity-mgt',
      name: 'workforce.activitymanagement',
      meta: {
        title: 'Activity Management',
      },
      redirect: '/workforce/activity-mgt/activity-type',
      children: [
        {
          path: 'activity-type-list',
          name: 'workforce.activitymanagement.activitytype',
          meta: {
            title: 'Activity Type',
          },
          redirect: '/workforce/activity-mgt/activity-type',
        },
      ],
    },
  ],
};
