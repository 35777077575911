<template>
  <div id="app">
    <Layout
      v-if="
        $route.path.includes('linehaul') ||
        $route.path.includes('cctv-management') ||
        $route.path.includes('wms-labormanagement') ||
        $route.path.includes('wms-dashboard') ||
        $route.path.includes('workforce')
      "
    />
    <router-view v-else />
    <wms-portal-target name="root" />
    <RemoteHostSetting />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { getCookie } from '@/utils/cookie';
import './assets/fonts/roboto/fonts.css';
import { getTranslateJSon } from '@/i18n/index';
import { tspLanguageMap } from '@/i18n/locales';
import { consoleBuildMsg } from './utils/consoleBuildMsg';
import Watermark from '@scfe-common/utils-watermark';
import { format } from '@/utils/date';
import { safeGet } from './utils/safeGet';
import Layout from '@/views/layout/index.vue';
import RemoteHostSetting from '@/components/remote-host-setting/index.vue';

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

@Component({
  name: 'App',
  components: {
    Layout,
    RemoteHostSetting,
  },
})
export default class App extends Vue {
  $i18n: any;
  $msgbox: any;
  $confirm: any;
  curWarehouseName: string = getCookie('setting_whs_v2') || '';
  waterMark: any = null;

  // 以 regionOverview 开头的 path
  get isRegionDashboardPage() {
    const regionOverviewReg = /^\/regionOverview/;
    const match = regionOverviewReg.test(String(this.$route?.path));
    return match;
  }

  mounted() {
    this.listenWarehouseChange();
    this.weakPasswordWarningShow();
    consoleBuildMsg();
  }
  // 获取当前翻译
  async created() {
    try {
      const backendLanguages = await this.$request.get(
        '/api/v2/apps/system/user/get_lang_setting'
      );
      const currentLanguage = backendLanguages.data.default;
      await getTranslateJSon(
        tspLanguageMap.get(currentLanguage) || currentLanguage
      );
    } catch (e) {
      console.log('error', e);
    }
  }

  updated() {
    if (this.waterMark) {
      this.removeMark();
    }
    this.setWaterMark();
  }

  weakPasswordWarningShow() {
    if (localStorage.getItem('weakPasswordWarning')) {
      const message = localStorage.getItem('weakPasswordWarning');
      localStorage.removeItem('weakPasswordWarning');
      this.$message({
        type: 'warning',
        message: this.$i18n(message),
        duration: 5000,
      });
    }
  }

  listenWarehouseChange() {
    document.addEventListener('visibilitychange', () => {
      const curURL = window.location.pathname;
      if (curURL === '/v2/login' || curURL === '/v2/tob/login') {
        return;
      }

      if (document.visibilityState === 'visible') {
        const whsCookieName = getCookie('setting_whs_v2');
        if (
          whsCookieName &&
          this.curWarehouseName &&
          this.curWarehouseName !== whsCookieName &&
          !this.isRegionDashboardPage // Region Dashboard 页面不提示刷新
        ) {
          this.$msgbox(
            this.$i18n(
              'The account login in warehouse is <span class="s-whs-red">%s</span>, it is different from the current warehouse <span class="s-whs-red">%s</span>, please reload.',
              [whsCookieName, this.curWarehouseName]
            ),
            this.$i18n('Notice'),
            {
              confirmButtonText: 'Confirm',
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              dangerouslyUseHTMLString: true,
              type: 'warning',
              messageType: 'warning',
            }
          ).then(() => {
            window.location.reload();
          });
        }
      }
    });
  }

  setWaterMark() {
    const target = document.querySelector('.app-main') as HTMLElement;
    if (!target) {
      return;
    }
    const nowTime = format(new Date());
    const emailAndTimeText = `${safeGet(
      this.$store.state,
      'user.userInfo.email'
    )}/${nowTime}`;
    const confidentialText = 'Confidential / SEA GROUP LTD';
    const texts = [emailAndTimeText, confidentialText];
    const font = '350 18px robot';
    const waterMarkConfig = {
      target,
      texts,
      font,
      color: 'rgba(0, 0, 0, 0.06)',
      vSpace: 30,
      rotate: 30,
    };
    this.waterMark = new Watermark(waterMarkConfig);
    this.waterMark.create();
  }
  removeMark() {
    this.waterMark.remove();
  }
}
</script>

<style lang="scss">
@import url('styles/index.scss');
#app {
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ssc-table tr th {
  background-color: #f5f6f9 !important;
}
.ssc-table th {
  color: #202934;
}
.no-line {
  border-bottom: none !important;
}
.ssc-select {
  height: 32px;
}
.ssc-tabs-nav .ssc-tabs-tab {
  margin-right: 0;
}
.ssc-dropdown-item {
  .ssc-button {
    font-size: 14px;
    color: #303844;
    font-weight: normal;
  }
  .ssc-button.ssc-btn-type-text {
    font-size: 14px;
    color: #006eff;
    font-weight: normal;
  }
  .ssc-button:hover.ssc-btn-type-text {
    text-decoration: none;
  }
}
.ssc-button.ssc-btn-disabled {
  opacity: 0.5;
}
.ssc-form-item {
  .ssc-input-number input {
    text-align: left;
    padding-left: 8px;
  }
}
.s-whs-red {
  color: #ee4d2d;
}
</style>
